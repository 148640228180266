export const setInLSArray = (variableName, data) => {
    let existingData = localStorage.getItem(variableName);
    if (!existingData || !Array.isArray(existingData)) {
        existingData = []
    }

    existingData.push(data);

    localStorage.setItem(variableName, JSON.stringify(existingData));
}

export const fetchFromLSArray = (variableName, key, value) => {
    let existingData = JSON.parse(localStorage.getItem(variableName));
    if (!existingData || !Array.isArray(existingData)) {
        return null;
    }

    const idx = existingData.findIndex(i => i[key] === value)
    if (idx >= 0)
        return existingData[idx]

    return null
}