import { useState, useContext } from "react";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import AdminPageContainer from "src/layouts/containers/AdminPageContainer";
import ListingForm from "src/components/collection/ListingForm";
import Loading from "src/components/Loading";
import { useSettingState, useErrorState, useGlobalState, useCollectionState, useListingState } from "src/state";
import { deploy, markApproved, markApprovedForAll, sellToken, getValueInContractDecimals } from "src/plugins/Ethereum";
import { admin_link } from "src/helpers/links.helper";
import { getERC20ContractAddress } from "src/helpers/blockchain.helper";

var bigInt = require("big-integer");

const Sell = () => {
	const { setErrorMessage } = useErrorState();
	const { getMarketplaceAddress } = useSettingState();
	const { createListing } = useListingState();

	const navigate = useNavigate();

	const { promised: isCollectionLoading, getCollection, updateSettings } = useCollectionState();

	const { id: collectionID, tokenId: tokenID } = useParams();

	const blockchainInfo = useContext(BlockchainContext);
	const [isSavingData, setIsSavingData] = useState(false);

	const marketplaceAddress = blockchainInfo ? getMarketplaceAddress(blockchainInfo.networkId) : null;

	const handleCreateListing = data => {
		if (data.listingType === "sale") return handleCreateSaleListing(data);
		else return handleCreateAuctionListing(data);
	};

	const handleCreateSaleListing = async data => {
		if (!marketplaceAddress) {
			setErrorMessage("Marketplace Contract is not deployed");
			return;
		}

		setIsSavingData(true);

		const collection = getCollection(collectionID);

		if (!collection) {
			setErrorMessage("Unable to load the collection. Please refresh and try again.");
			return;
		}

		//mark approved on blockchain
		markApprovedForAll(collection.contract_type, collection.address, marketplaceAddress)
			.then(() => {
				sellToken({
					contractType: collection.contract_type,
					contractAddress: collection.address,
					tokenID: tokenID,
					amount: data.amount,
					currency: data.currency,
					marketplaceAddress,
					royaltyPercentage: collection.royalty,
					networkID: collection.chainID,
				})
					.then(async () => {
						const dbID = await createListing({
							collectionID,
							tokenID,
							chainID: collection.chainID,
							creator: blockchainInfo.account,
							...data,
						});

						setIsSavingData(false);
						navigate(admin_link("/collections/" + collectionID + "/tokens"));
					})
					.catch(e => {
						console.log(e);
						setErrorMessage("Not able to approve marketplace contract to trade this NFT");
						setIsSavingData(false);
					});
			})
			.catch(e => {
				console.log(e);
				setErrorMessage("Not able to approve marketplace contract to trade this NFT");
				setIsSavingData(false);
			});
	};

	const handleCreateAuctionListing = async data => {
		// 1. deploy the auction contract with details
		// ** method, starting price, duration, transaction fee, target contract and tokenID
		// 2. mark approved the newly created contract to transfer the token
		// 3. add to db
		// 4. redirect to tokens page

		const collection = getCollection(collectionID);
		if (!collection) {
			setErrorMessage("Sorry! Could not complete the setup at this time, please try again");
			return;
		}

		const ERC20ContractAddress = getERC20ContractAddress(data.currency, collection.chainID);

		if (!ERC20ContractAddress) {
			setErrorMessage("Not able to approve marketplace contract to trade this NFT");
			return;
		}

		setIsSavingData(true);

		const amount = await getValueInContractDecimals(data.amount, ERC20ContractAddress);
		const increment = await getValueInContractDecimals(data.increment, ERC20ContractAddress);

		const contractType = ERC20ContractAddress === "0x0" ? "eth_auction" : "auction";

		deploy(contractType, {
			collectionID,
			tokenID,
			startingPrice: bigInt(amount).toString(),
			increment: bigInt(increment).toString(),
			ERC20ContractAddress,
			startTime: moment(data.startDate).format("X"),
			endTime: moment.utc(data.endDate + " 14:59:59").format("X"),
			marketplaceFee: 250,
		})
			.then(async result => {
				const auctionAddress = result.address;
				const creationTx = result.tx;

				// aprove contract to access token
				// ToDO: this needs to be removed and moved via marketplace contract
				markApproved(collection.contract_type, collection.address, tokenID, auctionAddress)
					.then(async () => {
						const dbID = await createListing({
							collectionID,
							tokenID,
							chainID: collection.chainID,
							creator: blockchainInfo.account,
							auctionAddress,
							creationTx,
							isWithNativeCurrency: true && ERC20ContractAddress === "0x0",
							...data,
						});

						navigate(admin_link("/collections/" + collectionID + "/tokens"));
					})
					.catch(error => console.log(error))

					.finally(() => {
						setIsSavingData(false);
					});
			})
			.finally(() => {
				setIsSavingData(false);
			});
	};

	return (
		<AdminPageContainer>
			<Typography variant="h1" mb={2}>
				List item for sale
			</Typography>
			<Grid container>
				<Grid item xs={6}>
					<Card>
						<CardContent>
							<ListingForm onCreate={handleCreateListing} />
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={6}></Grid>
			</Grid>
			{isSavingData && <Loading />}
		</AdminPageContainer>
	);
};

export default Sell;
