import { Link, useNavigate, useLocation } from "react-router-dom";
import { AppBar, Toolbar, experimentalStyled, Button, Box, Typography } from "@material-ui/core";
import { styled, alpha } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Logo from "../../components/Logo";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { shortAccount } from "src/helpers/blockchain.helper";
import { useContext, useState } from "react";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Menu from "src/components/PopperMenu";
import { useGlobalState } from "src/state";
// import useClickOutside from "src/hooks/useClickOutside";

const MainNavbar = props => {
	const [isToggled, setToggled] = useState(false);
	const toggleTrueFalse = () => setToggled(!isToggled);

	const [isActive, setIsActive] = useState({
		status: false,
		key: "",
	});

	const blockchainInfo = useContext(BlockchainContext);
	const { setRedirectToAfterConnect } = useGlobalState();
	const location = useLocation();

	const navigate = useNavigate();

	const connect = () => {
		setRedirectToAfterConnect(location.pathname);

		//if (blockchainInfo) blockchainInfo.connectToBlockchain();
		navigate("/connect-wallet");
	};

	const search = value => {
		navigate("/search/" + value);
	};

	const handleToggle = key => {
		if (isActive.key === key) {
			setIsActive({
				status: false,
			});
		} else {
			setIsActive({
				status: true,
				key,
			});
		}
	};

	// const domNode = useClickOutside(() => {
	// 	setIsActive({
	// 		status: false,
	// 	});
	// });

	return (
		<div className="header landing">
			<div className="container">
				<div className="row">
					<div className="col-xl-12">
						<div className="navigation">
							<nav className="navbar navbar-expand-lg navbar-dark">
								<div className="brand-logo">
									<Link to="/">
										<Logo />
									</Link>
								</div>
								<div
									className={isToggled ? "collapse navbar-collapse show" : "collapse navbar-collapse"}
								>
									
								</div>

								{/* <div className="signin-btn d-flex align-items-center">
									{blockchainInfo.account ? (
										<>
											<a className="btn btn-primary">
												Connected with {shortAccount(blockchainInfo.account)}
											</a>
										</>
									) : (
										<button className="btn btn-primary" onClick={connect}>
											Wallet
										</button>
									)}
								</div> */}
							</nav>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MainNavbar;
