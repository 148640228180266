import { createState, useState, postpone } from "@hookstate/core";
import { fetchUsers } from "src/db/firebase/users";

const usersState = createState(fetchUsers());
usersState.batch((state) => {
  if (state.promised) return postpone;
});

export function useUsersState() {
  const state = useState(usersState);

  return {
    get promised() {
      return state.promised;
    },
    get users() {
      return !state.promised ? state.get() : [];
    },
    getUser(walletAddress) {
      const user = !state.promised
        ? state.get().find((item) => item.id === walletAddress)
        : {};

      return user;
    },
  };
}
