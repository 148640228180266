import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => {
  return {
    square: {
      position: "relative",
      width: "100%",
      "&:after": {
        content: '""',
        display: "block",
        paddingBottom: "100%",
      },
    },
    content: {
      position: "absolute",
      width: "100%",
      height: "100%",
    },
  };
});

const SquareBlock = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.square}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default SquareBlock;
