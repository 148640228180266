import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Button, Container, TextField, Typography } from "@material-ui/core";
import { useCollectionState, useGlobalState, useProjectsState } from "src/state";
import Modal from "src/components/Modal";
import CollectionForm from "src/components/collection/CollectionForm";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import Loading from "src/components/Loading";
import CollectionsBlock from "src/components/frontend/Collections";
import { cerateAndDeployCollection } from "src/helpers/collections.helper";

const Collections = () => {
	const navigate = useNavigate();

	const { promised: isLoading, collections, createCollection, removeCollection } = useCollectionState();
	const { incrementCollectionCount } = useProjectsState();
	const { setRedirectToAfterConnect } = useGlobalState();

	const blockchainInfo = useContext(BlockchainContext);

	const [showAddCollectionForm, setShowAddCollectionForm] = useState(false);
	const handleModalClose = () => setShowAddCollectionForm(false);
	const [userCollections, setUserCollections] = useState(null);
	const [isSavingData, setIsSavingData] = useState(false);

	useEffect(() => {
		if (!blockchainInfo.account) {
			setRedirectToAfterConnect("/profile/collections");

			navigate("/connect-wallet");
		}
	}, [blockchainInfo]);

	useEffect(() => {
		if (collections.length && !userCollections) {
			const userCollections = collections.filter(collection => collection.owner === blockchainInfo.account);
			setUserCollections(userCollections);
		}
	}, [collections, blockchainInfo]);

	const AddCollectionButton = () => (
		<Box sx={{ py: 2, textAlign: "center" }}>
			<Button
				color="primary"
				disabled={isSavingData}
				size="large"
				type="submit"
				variant="contained"
				onClick={() => setShowAddCollectionForm(true)}
			>
				Add Collection
			</Button>
		</Box>
	);

	const handleOnCreateCollection = async data => {
		setShowAddCollectionForm(false);

		setIsSavingData(true);

		cerateAndDeployCollection(data, createCollection, removeCollection)
			.then(() => {
				//update project collection count if, added to project
				if (data.projectID) {
					incrementCollectionCount(data.projectID);
				}
			})
			.finally(() => setIsSavingData(false));
	};

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
				}}
			>
				<Container>
					<Box sx={{ mb: 3 }}>
						<Typography color="textPrimary" align="center" variant="h2">
							My Collections
						</Typography>
						{isSavingData && <Loading />}
						{isLoading ? (
							<Loading />
						) : !userCollections || userCollections.length === 0 ? (
							<>
								<Typography
									color="textSecondary"
									align="center"
									gutterBottom
									variant="body1"
									sx={{ my: 3 }}
								>
									You haven't added any collections yet
								</Typography>
								<AddCollectionButton />
							</>
						) : (
							<>
								<AddCollectionButton />
								<CollectionsBlock collections={userCollections} showEmpty={true} />
							</>
						)}
					</Box>
				</Container>
			</Box>
			<Modal
				open={showAddCollectionForm}
				onClose={handleModalClose}
				title="Add Collection"
				content={<CollectionForm isForFrontend={true} onCreate={handleOnCreateCollection} />}
			/>
		</>
	);
};

export default Collections;
