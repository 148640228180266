import { Box, Typography } from "@material-ui/core";
import SquareBlock from "src/components/frontend/SquareBlock";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
	attributeBlock: {
		padding: "20px !important",
		border: "3px solid #FFD700",
		background: "#FFF",
		display: "flex",
		flexDirection: "column !important",
		justifyContent: "center",
		borderRadius: "20px",
		boxShadow: "var(--st--shadows-0)",
		color: "#333",
	},
	attributeContent: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		wordBreak: "break-word",
	},
}));

const TokenAttribute = ({title, value}) => {
	const classes = useStyles();

	return (
		<Box className={classes.attributeBlock}>
			<SquareBlock>
				<div className={classes.attributeContent}>
					<Typography
						variant="h3"
						align="center"
						sx={{
							mb: 1,
							fontSize: "14px",
						}}
					>
						{value}
					</Typography>
					<Typography
						variant="caption"
						align="center"
						sx={{
							lineHeight: "normal",
							fontSize: "11px",
						}}
						color="textSecondary"
					>
						{title}
					</Typography>
				</div>
			</SquareBlock>
		</Box>
	);
};

export default TokenAttribute;