function getContractCodes() {
  const compiledCodes = require("src/contracts/ClaimContract.json");

  return compiledCodes;
}

export function getContractAddress() {
  return "0xC7bd40B3542eEF7f5E5379EEE181fA782ee3573E";
}

export const alreadyClaimed = () =>
  new Promise(async (resolve, reject) => {
    const web3 = window.web3;

    if (!web3) {
      reject(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }

    const compiledContract = getContractCodes();
    const contractAddress = getContractAddress();

    const contract = new web3.eth.Contract(
      compiledContract.abi,
      contractAddress
    );

    const accounts = await web3.eth.getAccounts();

    contract.methods
      .getClaim(accounts[0])
      .call({
        from: accounts[0],
      })
      .then(function (claim) {
        console.log("prevClaim", claim, claim.length);
        if (claim && claim.length === 2) {
          const amount = web3.utils.fromWei(claim[0].toString(), "ether");
          resolve([amount, claim[1]]);
        } else {
          resolve(false);
        }
      })
      .catch((e) => {
        resolve(false);
      });
  });

export const claim = (amount) =>
  new Promise(async (resolve, reject) => {
    const web3 = window.web3;

    if (!web3) {
      reject(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }

    const compiledContract = getContractCodes();
    const contractAddress = getContractAddress();

    const contract = new web3.eth.Contract(
      compiledContract.abi,
      contractAddress
    );

    const accounts = await web3.eth.getAccounts();
    const amountInWei = web3.utils.toWei(amount.toString(), "ether");

    console.log(amountInWei);

    contract.methods
      .requestClaim(amountInWei)
      .send({ from: accounts[0] })
      .once("receipt", (receipt) => {
        if (receipt.transactionHash) resolve(receipt.transactionHash);
        else reject();
      })
      .catch((e) => {
        reject(e);
      });
  });

export const claims = () =>
  new Promise(async (resolve, reject) => {
    const web3 = window.web3;

    if (!web3) {
      reject(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }

    const compiledContract = getContractCodes();
    const contractAddress = getContractAddress();

    const contract = new web3.eth.Contract(
      compiledContract.abi,
      contractAddress
    );

    const accounts = await web3.eth.getAccounts();

    contract.methods
      .totalClaims()
      .call({
        from: accounts[0],
      })
      .then(async (count) => {
        const response = [];
        if (count) {
          for (let i = 0; i < count; i++) {
            try {
              const claimFrom = await contract.methods
                .claimRequestsFrom(i)
                .call({
                  from: accounts[0],
                });

              if (claimFrom) {
                const claim = await contract.methods.getClaim(claimFrom).call({
                  from: accounts[0],
                });

                if (claim && claim.length === 2) {
                  const amount = web3.utils.fromWei(
                    claim[0].toString(),
                    "ether"
                  );
                  response.push([claimFrom, amount, claim[1]]);
                }
              }
            } catch (e) {
              break;
            }
          }
        }

        resolve(response);
      })
      .catch((e) => reject(e));
  });

export const settleClaim = (forWalletAddress) =>
  new Promise(async (resolve, reject) => {
    const web3 = window.web3;

    if (!web3) {
      reject(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }

    const compiledContract = getContractCodes();
    const contractAddress = getContractAddress();

    const contract = new web3.eth.Contract(
      compiledContract.abi,
      contractAddress
    );

    const accounts = await web3.eth.getAccounts();

    contract.methods
      .settleClaim(forWalletAddress)
      .send({ from: accounts[0] })
      .once("receipt", (receipt) => {
        if (receipt.transactionHash) resolve(receipt.transactionHash);
        else reject();
      })
      .catch((e) => {
        reject(e);
      });
  });
