import { useEffect } from "react";

const About = () => {
	const elHash = window.location.hash;

	useEffect(() => {
		const d = document.getElementById(elHash.replace("#", ""));
		if (d) {
			const topPos = d.offsetTop;
			if (topPos >= 0) window.scrollTo(0, topPos);
		}
	}, [elHash]);
	return (
		<>
			<div id="veblenian" className="what-we-do section-padding">
				<div className="container">
					<div className="row align-items-center justify-content-between">
						<div className="col-lg-5">
							<div className="what-we-do-img">
								<img src="/assets/images/veblenian.png" alt="" className="img-fluid rounded" />
							</div>
						</div>
						<div className="col-lg-6">
							<div className="what-we-do-content">
								<h3>Veblenian</h3>
								<p>
									Created by Queen Veblen who uses her LifeStone to bring forth a multiverse dimension
									for every species with the love for culture and fashion. She honors all with a "M.V
									RING" bringing peace and poise in Veblen.
								</p>
								<p>
									MVUG is the abbreviation of fashion DAO rendering universal good brands the power to
									shift the universe.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="governance" className="governance section-padding">
				<div className="container">
					<div className="row align-items-center justify-content-between">
						<div className="col-lg-12">
							<div className="governance-content">
								<h3>Governance</h3>
								<p>
									Governed by veblen species representing 1002 fashion governors and
									102,000,000,000,000 Veblenian across the multiverse. Every 90 universal days, the
									multiverse opens a gateway filled with diamonds and gems airdrop and collated into
									the V-Ring. The Veblenian uses this to trade and exchange their fashion wear
									representing the couture of their ethnicity.
								</p>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="governance-img">
								<img src="/assets/images/governance.png" alt="" className="img-fluid" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="show" className="governance section-padding">
				<div className="container">
					<div className="row align-items-center justify-content-between">
						<div className="col-lg-12">
							<div className="governance-content">
								<h3>V Show</h3>
								<p>
									The fashion multiverse showcases the future trends and celebrates the perfect union
									between the Veblen good and the Veblenian.
								</p>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="governance-img">
								<img
									src="/assets/images/vshow.jpg"
									alt=""
									className="img-fluid"
									style={{ height: "300px", objectFit: "cover" }}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="mvpay" className="governance section-padding">
				<div className="container">
					<div className="row align-items-center justify-content-between">
						<div className="col-lg-12">
							<div className="governance-content">
								<h3>MVPay</h3>
								<h3 className="default-color">
									Every USDT paid we will donate 10% to Culture Tribe Designers that creates Textile
									and Fashion.
								</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="privilege" className="governance section-padding">
				<div className="container">
					<div className="row align-items-center justify-content-between">
						<div className="col-lg-12">
							<div className="governance-content">
								<h3>MVUG Privilege</h3>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-3 col-md-6">
							<div className="team-content">
								<h3>COLLECTIONS</h3>
								<p>ACCESS TO RARITY</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="team-content">
								<h3>GIVING</h3>
								<p>ACCESS TO CHARITY</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="team-content">
								<h3>ART</h3>
								<p>ACCESS TO CLARITY</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="team-content">
								<h3>BLOCKCHAIN</h3>
								<p>ACCESS TO SURETY</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="journey" className="what-we-do section-padding">
				<div className="container">
					<div className="row align-items-center justify-content-between">
						<div className="col-lg-12">
							<div className="governance-content">
								<h3>MV Journey</h3>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-12">
							<section id="cd-timeline" class="cd-container">
								<div class="cd-timeline-block">
									<div class="cd-timeline-img cd-picture"></div>

									<div class="cd-timeline-content">
										<h2>March 22</h2>
										<div class="timeline-content-info">
											<span class="timeline-content-info-title">
												Creating origins of each Multiverse Veblenians
											</span>
										</div>
									</div>
								</div>
								<div class="cd-timeline-block">
									<div class="cd-timeline-img cd-picture"></div>

									<div class="cd-timeline-content">
										<h2>APRIL 22</h2>
										<div class="timeline-content-info">
											<span class="timeline-content-info-title">
												Launch the MV website and NFT marketplace &amp; plan DAO
											</span>
										</div>
										<div class="timeline-content-info" style={{ marginTop: "5px" }}>
											<span class="timeline-content-info-title">
												Sourcing 8 rare Veblen goods merging with original Veblenians
											</span>
										</div>
									</div>
								</div>
								<div class="cd-timeline-block">
									<div class="cd-timeline-img cd-movie"></div>

									<div class="cd-timeline-content">
										<h2>May 22</h2>
										<div class="timeline-content-info">
											<span class="timeline-content-info-title">DAO Activation</span>
										</div>
									</div>
								</div>
								<div class="cd-timeline-block">
									<div class="cd-timeline-img cd-picture"></div>

									<div class="cd-timeline-content">
										<h2>June 22</h2>
										<div class="timeline-content-info">
											<span class="timeline-content-info-title">Crypto Asia NFT showcase</span>
										</div>
										<div class="timeline-content-info" style={{ marginTop: "5px" }}>
											<span class="timeline-content-info-title">
												Custodian service for luxury goods to match NFT at MVSG Boutiques
											</span>
										</div>
									</div>
								</div>
								<div class="cd-timeline-block">
									<div class="cd-timeline-img cd-picture"></div>

									<div class="cd-timeline-content">
										<h2>July 22</h2>
										<div class="timeline-content-info">
											<span class="timeline-content-info-title">
												Nominate the next 20 Veblen goods
											</span>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default About;
