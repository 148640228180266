import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import SearchBar from "src/components/SearchBar";

const _Toolbar = ({ onPressAddButton, onSearch, ...props }) => (
  <Box {...props}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="h2" mr={1} color="textPrimary">
        Projects
      </Typography>
      <Button color="primary" variant="contained" onClick={onPressAddButton}>
        Add Project
      </Button>
    </Box>
    <Box sx={{ mt: 3 }}>
      <Card>
        <CardContent style={{ paddingBottom: 16 }}>
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Grid item xs={8}>
              <SearchBar
                sx={{ maxWidth: 500 }}
                placeholder="Search Projects"
                onSearch={onSearch}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  </Box>
);

export default _Toolbar;
