import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

export const LinkableExternal = ({ isLinked, to, children, ...props }) =>
  isLinked ? (
    <a href={to} target="_blank" rel="noreferrer" {...props}>
      {children}
    </a>
  ) : (
    <Typography variant="" {...props}>{children}</Typography>
  );

export const LinkableLocal = ({ isLinked, to, children, ...props }) =>
  !!isLinked && to ? (
    <Link to={to} {...props}>{children}</Link>
  ) : (
    <Typography {...props}>{children}</Typography>
  );
