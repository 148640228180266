import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { admin_link } from "src/helpers/links.helper";
import { networkName, shortAccount } from "src/helpers/blockchain.helper";
import { LinkableLocal as Linkable } from "src/components/Linkable";
import FillInContractAddress from "src/components/collection/FillInContractAddress";

const _Card = ({ collection, ...rest }) => {
  return (
    <Linkable
      to={admin_link(`/collections/${collection.address}/tokens`)}
      isLinked={!collection.pending}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
        {...rest}
      >
        <CardContent align="center">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pb: 3,
            }}
          >
            <Avatar
              alt="Collection"
              src={collection.media}
              variant="rounded"
              sx={{ width: 70, height: 70 }}
            >
              {collection.symbol}
            </Avatar>
          </Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h4"
          >
            {collection.name}
          </Typography>
          {collection.pending ? (
            <Box>
              <CircularProgress size={15} />
            </Box>
          ) : (
            <Typography
              className="ellipses"
              align="center"
              color="textSecondary"
              variant="body2"
            >
              {collection.address}
            </Typography>
          )}
          {collection.chainID && (
            <Typography align="center" color="textSecondary" variant="caption">
              ({collection.pending ? "Deploying Contract to " : "On "}
              {networkName(collection.chainID)}{" "}
              {!collection.pending
                ? " by " + shortAccount(collection.owner)
                : ""}
              )
            </Typography>
          )}
        </CardContent>
        <Box sx={{ flexGrow: 1 }} />
        <Divider />
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
            <Grid
              item
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <AccessTimeIcon color="action" />
              <Typography
                color="textSecondary"
                display="inline"
                sx={{ pl: 1 }}
                variant="body2"
              >
                Updated {moment(collection.updatedAt).fromNow()}
              </Typography>
            </Grid>

            <Grid
              item
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              {collection.pending &&
              moment(collection.createdAt).isBefore(
                moment().subtract(1, "minutes")
              ) ? (
                <FillInContractAddress collection={collection} />
              ) : (
                <>
                  <ListAltIcon color="action" />
                  <Typography
                    color="textSecondary"
                    display="inline"
                    sx={{ pl: 1 }}
                    variant="body2"
                  >
                    {collection.totalItems ?? 0} Items
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Linkable>
  );
};

_Card.propTypes = {
  collection: PropTypes.object.isRequired,
};

export default _Card;
