import { Box, Button, Typography } from "@material-ui/core";

const _Toolbar = ({
  title,
  showAddButton = false,
  onClickAddButton = () => {},
  ...props
}) => (
  <Box {...props}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "24px",
      }}
    >
      <Typography variant="h2" color="textPrimary" gutterBottom>
        {title}
      </Typography>
      {showAddButton && (
        <Box>
          <Button variant="contained" onClick={onClickAddButton}>Add New</Button>
        </Box>
      )}
    </Box>
  </Box>
);

export default _Toolbar;
