import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useListingState } from "src/state";
import Loading from "src/components/Loading";
import Listings from "src/components/frontend/Listings";

const SearchResults = () => {
  const { keyword } = useParams();
  const { promised: isListingLoading, listings } = useListingState();
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(true);

  useEffect(() => {
    if (!isListingLoading) {
      const result = listings.filter((item) => true);
      setSearchResults(result);
      setSearching(false);
    }
  }, [keyword, isListingLoading, listings]);

  if (isListingLoading) return <Loading />;

  return (
    <Box className="container">
      <h2 style={{ textAlign: "center" }}>Search Results</h2>
      {searching ? (
        <Loading />
      ) : (
        <Listings
          listings={searchResults}
          gridOptions={{ xs: 12, sm: 6, md: 3, lg: 2 }}
        />
      )}
    </Box>
  );
};

export default SearchResults;
