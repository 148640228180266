import { useEffect, useMemo, useReducer } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { firebaseConfig } from "./Firebase";

try {
	firebase.initializeApp(firebaseConfig);
} catch (e) {}

const auth = firebase.auth();

const logInWithEmailAndPassword = async (email, password) => {
	try {
		const res = await auth.signInWithEmailAndPassword(email, password);
		return res.user;
	} catch (err) {
		console.error(err);
	}

	return null;
};

const registerWithEmailAndPassword = async (email, password, username) => {
	try {
		const res = await auth.createUserWithEmailAndPassword(email, password);
		const user = auth.currentUser;
		user.updateProfile({ displayName: username });
		return user;
	} catch (err) {
		return { error: err.message };
	}
};

const sendEmailVerification = async () => {
	try {
		const res = await auth.currentUser.sendEmailVerification();
	} catch (err) {
		console.error(err);
	}
};

const checkIsEmailVerified = async () => {
	try {
		const user = auth.currentUser;
		if (user) {
			await user.reload();
			if (user.emailVerified) return true;
			else return false;
		}
	} catch (err) {
		console.error(err);
	}

	return false;
};

const sendPasswordReset = async email => {
	try {
		await auth.sendPasswordResetEmail(email);
		alert("Password reset link sent!");
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};

const logout = () => {
	auth.signOut();
};

export {
	auth,
	logInWithEmailAndPassword,
	registerWithEmailAndPassword,
	sendEmailVerification,
	checkIsEmailVerified,
	sendPasswordReset,
	logout,
};
