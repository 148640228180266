import { useRef } from "react";
import * as Yup from "yup";
import { Form, useFormik, FormikProvider } from "formik";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import TextField from "src/components/FormikTextField";
import ImagePicker from "src/components/ImagePicker";
import { readBlobToBuffer, uploadFileToIPFS } from "src/helpers/file.helper";
import { hash_to_ipfs_link } from "src/helpers/links.helper";

const CategoryForm = ({ onSave, defaultData }) => {
  const selectedImageBuffer = useRef(null);

  const formik = useFormik({
    initialValues: {
      category_name: defaultData ? defaultData.name : "",
    },
    onSubmit: async (values, actions) => {
      //upload image if selected
      if (selectedImageBuffer.current) {
        const fileHash = await uploadFileToIPFS(selectedImageBuffer.current);
        if (fileHash) {
          values.category_photo = hash_to_ipfs_link(fileHash);
        }
      }
      actions.setSubmitting(false);
      onSave && onSave(values);
    },
    validationSchema: Yup.object().shape({
      category_name: Yup.string()
        .max(255)
        .required("Category Name is required"),
    }),
  });

  const handleFileSelect = (filename, file) => {
    readBlobToBuffer(file).then(
      (imageBuffer) => (selectedImageBuffer.current = imageBuffer)
    );
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <TextField
          fullWidth
          label="Name"
          margin="normal"
          name="category_name"
          type="text"
          variant="outlined"
        />
        <Box my={2}>
          <Typography variant="h6" mb={1}>
            Category Image
          </Typography>
          <ImagePicker
            withDragDrop
            name="timeline_image"
            onFileSelect={handleFileSelect}
            value={defaultData ? defaultData.photo : ""}
          />
        </Box>
        <Button
          fullWidth
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {formik.isSubmitting ? <CircularProgress size={20} /> : "Save"}
        </Button>
      </Form>
    </FormikProvider>
  );
};

export default CategoryForm;
