// const IPFS = require("ipfs-api");
// const ipfs = new IPFS({
//   host: "ipfs.infura.io",
//   port: 5001,
//   protocol: "https",
// });

import { convertBufferToBlob } from "src/helpers/file.helper";

const ipfs = {
	files: {
		add: async (fileBuffer, callback) => {
			try {
				const formData = new FormData();
				formData.append("file", convertBufferToBlob(fileBuffer), "file");

				fetch("https://api.pinata.cloud/pinning/pinFileToIPFS", {
					method: "post",
					body: formData,
					headers: {
						pinata_api_key: `${process.env.REACT_APP_PINATA_KEY}`,
						pinata_secret_api_key: `${process.env.REACT_APP_PINATA_SERCRET}`,
					},
				})
					.then(response => response.json())
					.then(data => {
						const ipfsHash = [{ hash: data.IpfsHash }];
						callback(false, ipfsHash);
					});
			} catch (error) {
				console.log("Error sending File to IPFS: ");
				console.log(error);
				callback(true);
			}
		},
	},
};

export const readFileByHash = ipfsHash =>
	new Promise((resolve, reject) => {
		fetch(process.env.REACT_APP_IPFS_BASE_URL + "ipfs/" + ipfsHash)
			.then(response => response.json())
			.then(metadata => {
				resolve(metadata);
			})
			.catch(error => reject(error));
	});

export default ipfs;
