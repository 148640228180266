import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { Typography } from "@material-ui/core";

const CopyToClipboard = ({ text, copyText }) => {
  const [copiedText, setCopiedText] = useState(false);

  const copyIt = () => {
    navigator.clipboard.writeText(copyText ?? text)
    setCopiedText(true);
  }

  return (
    <>
      <Grid item xs={12} component={Box}>
        <Tooltip
          title={
            copiedText
              ? "This was Copied!"
              : "Copy To Clipboard"
          }
          placement="top"
        >
          <Box
            component="button"
            fontFamily="inherit"
            fontSize="16px"
            fontWeight="400"
            lineHeight="1.25"
            display="inline-block"
            width="100%"
            margin=".5rem 0"
            padding="14px"
            textAlign="left"
            border="0"
            borderRadius="4px"
            data-clipboard-text="album-2"
            type="button"
            onClick={copyIt}
            className="anchor"
          >
            <Typography variant="body1">{text}</Typography>
          </Box>
        </Tooltip>
      </Grid>
    </>
  );
};

export default CopyToClipboard;
