import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Container } from "@material-ui/core";
import Logo from "../../components/Logo";
import { admin_link } from "src/helpers/links.helper";
import { useSessionState } from "src/state";
import EmailLogin from "src/components/access/EmailLogin";

const Login = () => {
	const navigate = useNavigate();
	const sessionState = useSessionState();

	const [errorMessage, setErrorMessage] = useState("");

	const handleLoginSuccess = userInfo => {
		sessionState.loggedInUser = { username: userInfo.email };
		navigate(admin_link("/"), { replace: true });
	};

	const handleLoginFailed = message => {
		setErrorMessage(message);
	};

	return (
		<>
			<Box
				sx={{
					backgroundColor: "background.default",
					display: "flex",
					flexDirection: "column",
					height: "100%",
					justifyContent: "center",
				}}
			>
				<Box sx={{ mb: 3, textAlign: "center", maxWidth: "400px", mx: "auto" }}>
					<Logo />
				</Box>
				<Container maxWidth="sm">
					<EmailLogin onLoginSuccess={handleLoginSuccess} onLoginFailed={handleLoginFailed} />
					{errorMessage && <Alert severity="error">{errorMessage}</Alert>}
				</Container>
			</Box>
		</>
	);
};

export default Login;
