import { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import ListingItem from "src/components/frontend/ListingItem";

const CollectionsContainer = styled("div")(({ theme }) => ({
  //padding: "50px 0",
}));

const Collections = ({ collections, showEmpty, title, ...props }) => {
  // const [displayCollections, setDisplayCollections] = useState([]);
  // const page = useRef(1);

  // const itemsPerPage = 100;

  // useEffect(() => {
  //   loadMore();
  // }, [collections]);

  // const loadMore = () => {
  //   if (displayCollections.length >= collections.length) return;

  //   const pageCollections = collections.slice(
  //     (page.current - 1) * itemsPerPage,
  //     page.current * itemsPerPage
  //   );
  //   const displayedCollections = [...displayCollections, ...pageCollections];

  //   setDisplayCollections(displayedCollections);
  //   page.current = page.current + 1;
  // };

  return (
    <CollectionsContainer>
      <Box className="container">
        {title && <h2 style={{ marginBottom: "15px" }}>{title}</h2>}
        {collections.length > 0 ? (
          <Grid container spacing={3}>
            {collections.map((collection, index) =>
              collection ? (
                <ListingItem
                  key={collection.address}
                  listing={{ collectionID: collection.address, tokenID: 1, type: "collection" }}
                  name={collection.name}
                  description={collection.symbol}
                  linkTo={`/collection/${collection.address}`}
                  gridOptions={{ xs: 12, sm: 4, md: 2 }}
                  showEmpty={showEmpty ?? false}
                />
              ) : null
            )}
          </Grid>
        ) : (
          <Typography>
            There are no items to show, please check back again!
          </Typography>
        )}
        {/* <Box sx={{ textAlign: "center", my: 3 }}>
          {collections.length > displayCollections.length && (
            <Button variant="contained" color="primary" onClick={loadMore}>
              Load More
            </Button>
          )}
        </Box> */}
      </Box>
    </CollectionsContainer>
  );
};

export default Collections;
