import Button from "@material-ui/core/Button";
import { useNavigate } from "react-router";
import { useGlobalState } from "src/state";

const ListButton = ({ tokenInfo }) => {
  const { chainID, collectionID, tokenID } = tokenInfo;
  const { toggleListingModalVisible, setListingForToken } = useGlobalState();
  const navigate = useNavigate();

  const initListing = () => {
    // toggleListingModalVisible();
    // setListingForToken(tokenInfo);
    navigate("list/"+tokenInfo.tokenID)
    //console.log(tokenInfo);
  };

  return (
    <>
      <Button
        sx={{ ml: 2 }}
        variant="contained"
        color="success"
        onClick={() => initListing()}
        size="small"
      >
        List
      </Button>
    </>
  );
};

export default ListButton;
