import {
  Box,
  InputAdornment,
  Button,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { claims, getContractAddress, settleClaim as settleClaimForWallet } from "src/plugins/EthereumClaimDemo";
import { approveFunds } from "src/plugins/Ethereum";
import { BlockchainContext } from "src/providers/BlockchainProvider";

const Settle = () => {
  const blockchainInfo = useContext(BlockchainContext);
  const [totalClaims, setTotalClaims] = useState(0);
  const [claimsListing, setClaimsListing] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (blockchainInfo.account) {
      setLoading(true);
      claims().then((claims) => {
        setTotalClaims(claims.length);
        setClaimsListing(claims);
        setLoading(false);
      });
    }
  }, [blockchainInfo]);

  const claimContract = getContractAddress();

  const settleClaim = async (walletAddress, amount) => {
    setLoading(true);
    const approved = await approveFunds(claimContract, 500, "WETH", 3);

    if (approved) {
      await settleClaimForWallet(walletAddress);

      claims().then((claims) => {
        setTotalClaims(claims.length);
        setClaimsListing(claims);
        setLoading(false);
      });
    } else
      setLoading(false);
  };

  return (
    <Box className="container" py={10}>
      <h1>Settle Claims</h1>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box sx={{ mt: 3 }}>
          <h3>
            {totalClaims
              ? "Following claims are made"
              : "No claims made yet, or your are not authorized to view claims"}
          </h3>
          {claimsListing.map((item, index) => (
            <Grid
              container
              key={index}
              gap="2"
              sx={{ alignItems: "center" }}
            >
              <Grid item p={2}>
                {item[0]}
              </Grid>
              <Grid item p={2}>
                WETH {item[1]}
              </Grid>
              <Grid item p={2}>
                {parseInt(item[2]) ? (
                  "Settled"
                ) : (
                  <Button
                    onClick={() => settleClaim(item[0], item[1])}
                    variant="contained"
                  >
                    Settle Now
                  </Button>
                )}
              </Grid>
            </Grid>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Settle;
