import React, { useEffect, useState, createContext } from "react";
import { loadWeb3, detectNetworkAccount } from "src/plugins/Web3Connection";
import { useSessionState } from "src/state";

export const BlockchainDefaultData = {
  account: null,
  networkId: null,
  connectToBlockchain: () => {},
};

export const BlockchainContext = createContext(BlockchainDefaultData);

const BlockchainProvider = (props) => {
  const [tryReconnect, setTryReconnect] = useState(0);
  const [blockchainInfo, setBlockchainInfo] = useState(BlockchainDefaultData);

  const { fetchUserProfile, logout } = useSessionState();

  const updateBlockchainInfo = () => {
    try {
      detectNetworkAccount()
        .then((info) => {
          setBlockchainInfo(info);

          // fetch the user info from db
          fetchUserProfile(info.account)
        })
        .catch((err) => {
          // user disconnected the account
          setBlockchainInfo(BlockchainDefaultData);
          logout()
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (tryReconnect === 0) return;

    loadWeb3().then(() => {
      if (window.web3) {
        window.ethereum.on("accountsChanged", function (accounts) {
          updateBlockchainInfo();
        });

        window.ethereum.on("chainChanged", function (network) {
          updateBlockchainInfo();
        });

        updateBlockchainInfo();
      }
    });

    setTryReconnect(0);
  }, [tryReconnect]);

  const connectToBlockchain = () => setTryReconnect(1);

  return (
    <BlockchainContext.Provider
      value={{ ...blockchainInfo, connectToBlockchain }}
    >
      {props.children}
    </BlockchainContext.Provider>
  );
};

export default BlockchainProvider;
