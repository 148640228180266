import db, {FirebaseFieldValue} from "src/plugins/Firebase";

const firestore_collection_name = "categories";

export const setupCategoryData = (dbID, itemData) => {
  return {
    id: dbID,
    name: itemData.name,
    photo: itemData.photo,
    listingCount: itemData.listing_count ?? 0,
  };
};

// Fetch Records
export const fetchCategories = async () => {
  const dbResponse = db.collection(firestore_collection_name);
  const dbData = await dbResponse.get();

  const data = [];

  dbData.docs.forEach((item) => {
    data.push(setupCategoryData(item.id, item.data()));
  });

  return data;
};

export const fetchCategory = async (categoryID) => {
  const dbData = await db
    .collection(firestore_collection_name)
    .doc(categoryID)
    .get();

  return setupCategoryData(dbData.id, dbData.data());
};

export const addNewCategory = async ({ name, photo }) => {
  let dbID = null;

  await db
    .collection(firestore_collection_name)
    .add({
      name,
      photo,
      listing_count: 0,
    })
    .then((docRef) => {
      dbID = docRef.id;
    });

  return dbID;
};

export const updateCategory = (categoryID, data) => {
  db.collection(firestore_collection_name).doc(categoryID).update({
    name: data.name,
    photo: data.photo,
  });
};

export const changeListingCount = (categoryID, changeBy) => {
  db.collection(firestore_collection_name)
    .doc(categoryID)
    .update({
      listing_count: FirebaseFieldValue.increment(changeBy)
    });
};

export const deleteCategory = async (categoryID) => {
  db.collection(firestore_collection_name)
    .doc(categoryID)
    .delete();
};
