import { Box, Button, TextField, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import { logInWithEmailAndPassword } from "src/plugins/FirebaseAuth";

const EmailLogin = ({ onLoginSuccess, onLoginFailed }) => {
	const defaultLogin = {
		email: "",
		password: "",
	};
	
	return (
		<Formik
			initialValues={{ ...defaultLogin }}
			validationSchema={Yup.object().shape({
				email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
				password: Yup.string().max(255).required("Password is required"),
			})}
			onSubmit={(values, { setSubmitting }) => {
				logInWithEmailAndPassword(values.email, values.password).then(res => {
					if (res && res.email) {
						onLoginSuccess(res);
					} else {
						onLoginFailed("Wrong username or password!");
					}
				});

				setSubmitting(false);
			}}
		>
			{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
				<form onSubmit={handleSubmit}>
					<Box sx={{ mb: 3 }}>
						<Typography color="textPrimary" align="center" variant="h2">
							Sign in
						</Typography>
						<Typography color="textSecondary" align="center" gutterBottom variant="body2">
							Sign in on the internal platform
						</Typography>
					</Box>
					<TextField
						error={Boolean(touched.email && errors.email)}
						fullWidth
						helperText={touched.email && errors.email}
						label="Email Address"
						margin="normal"
						name="email"
						onBlur={handleBlur}
						onChange={handleChange}
						type="email"
						value={values.email}
						variant="outlined"
					/>
					<TextField
						error={Boolean(touched.password && errors.password)}
						fullWidth
						helperText={touched.password && errors.password}
						label="Password"
						margin="normal"
						name="password"
						onBlur={handleBlur}
						onChange={handleChange}
						type="password"
						value={values.password}
						variant="outlined"
					/>
					<Box sx={{ py: 2 }}>
						<Button
							color="primary"
							disabled={isSubmitting}
							fullWidth
							size="large"
							type="submit"
							variant="contained"
						>
							Sign in
						</Button>
					</Box>
				</form>
			)}
		</Formik>
	);
};

export default EmailLogin;
