import React, { useContext, useEffect, useRef, useState } from "react";
import Hero from "src/components/frontend/Hero";
import Loading from "src/components/Loading";
import { useListingState, useCollectionState, useSettingState, useProjectsState, useTokenState } from "src/state";
import Collections from "src/components/frontend/Collections";
import Projects from "src/components/frontend/Projects";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { Box } from "@material-ui/core";
import Modal from "src/components/Modal";
import YoutubeVideoPlayer from "src/components/frontend/YoutubeVideoPlayer";
import { pluck } from "src/helpers/array.helper";
import { cachedVersion } from "src/helpers/file.helper";
import Listings from "src/components/frontend/Listings";

const LaunchpadBlock = () => {
	const { getSetting } = useSettingState();
	const videoURL = getSetting("header_video");

	return (
		<div className="intro1 section-padding">
			<div className="container">
				<div className="row align-items-center justify-content-between">
					<div className="col-xl-5">
						<div className="intro-content">
							<p>METAVEBLEN IS THE MULTIVERSE</p>
							<h1 style={{ color: "#0299bd" }}>Fashion DAO Coalescing With Luxury Goods</h1>
							<div className="intro-search">
								<form action="#">
									<input type="text" placeholder="Search Here" />
									<span>
										<i className="ri-search-line"></i>
									</span>
								</form>
							</div>
						</div>
					</div>
					<div className="col-xl-6">
						<div className="intro-slider">
							{videoURL && videoURL !== "" && <YoutubeVideoPlayer youtubeUrl={videoURL} />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const MissionBlock = () => (
	<div className="mission-vission section-padding bg-light">
		<div className="container">
			<div className="row">
				<div className="col-md-6">
					<div className="mission-vission-content">
						<span>
							<i className="ri-send-plane-fill"></i>
						</span>
						<h6>Our Mission</h6>
						<h3>Create Ecosystem For Blockchain Gaming</h3>
						<p>
							To help create a better world by embracing blockchain-powered emerging technologies. We
							believe we are in a pivotal moment in history where humanity is preparing for the New Normal
							Virtual worlds will accelerate us into this new reality, revolutionizing how we live, work
							and play.
						</p>
					</div>
				</div>
				<div className="col-md-6">
					<div className="mission-vission-content">
						<span>
							<i className="ri-eye-fill"></i>
						</span>
						<h6>OUR VISION</h6>
						<h3>Building A Community</h3>
						<p>
							To build a thriving ecosystem for blockchain gaming and introduce new ways for players earn
							crypto in a fun, engaging way. Community is the core of our organization, and we’re building
							a tribe of forward-thinking early-adopters, creators and supporters.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
);

const PartnersBlock = () => (
	<div className="investor section-padding bg-light">
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-xl-6">
					<div className="section-title text-center">
						<h2>Our Network of Partners</h2>
					</div>
				</div>
			</div>
			<div className="row justify-content-center align-items-center">
				<div className="col-auto">
					<div className="investor-brand">
						<img src="/assets/images/brand/4.png" className="img-fluid" alt="" />
					</div>
				</div>
				<div className="col-auto">
					<div className="investor-brand">
						<img src="/assets/images/brand/1.png" className="img-fluid" alt="" />
					</div>
				</div>
				<div className="col-auto">
					<div className="investor-brand">
						<img src="/assets/images/brand/2.png" className="img-fluid" alt="" />
					</div>
				</div>
				<div className="col-auto">
					<div className="investor-brand">
						<img src="/assets/images/brand/3.png" className="img-fluid" alt="" />
					</div>
				</div>
				<div className="col-auto">
					<div className="investor-brand">
						<img src="/assets/images/brand/5.png" className="img-fluid" alt="" />
					</div>
				</div>
			</div>
		</div>
	</div>
);

const Home = () => {
	// const blockchainInfo = useContext(BlockchainContext);
	const {
		promised: isListingLoading,
		listings,
		//fetch: fetchListings,
	} = useListingState();

	const collectionID = "0x0410320527D34E759857f87321603de2A6b2332d";

	const { promised: isCollectionLoading, getCollection } = useCollectionState();
	const { promised: isTokenLoading, tokens } = useTokenState(collectionID);

	const { promised: isProjectsLoading, projects } = useProjectsState();
	const featured = useRef(-1);

	const mainContentRef = useRef(null);

	const [openVideoPopup, setOpenVideoPopup] = useState(false);
	const [playingVideo, setPlayingVideo] = useState(false);

	const [listedCollections, setListedCollections] = useState([]);
	const collectionsLoaded = useRef(false);

	const collection = getCollection(collectionID);

	const openVideo = videoURL => {
		setPlayingVideo(videoURL);
		setOpenVideoPopup(true);
	};

	const handleModalClose = () => {
		setOpenVideoPopup(false);
		setPlayingVideo(false);
	};

	const scrollToMainContent = (e) => {
		e.preventDefault();

		mainContentRef.current?.scrollIntoView({ behavior: 'smooth' });
	}

	return (
		<>
			<div className="header-video-container">
				<video autoPlay muted loop playsInline style={{ width: "100%" }}>
					<source src="assets/images/header-video.mp4" type="video/mp4" />
				</video>
				<a className="page-scroll" href="#" onClick={scrollToMainContent}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						fill="currentColor"
						className="bi bi-chevron-up"
						viewBox="0 0 16 16"
					>
						<path
							fillRule="evenodd"
							d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
						/>
					</svg>
				</a>
			</div>
			<div ref={mainContentRef} className="section-padding">
				<Box className="container">
					{/* {!process.env.REACT_APP_HAVE_PROJECTS && isCollectionsLoading ? (
					<Loading />
				) : !process.env.REACT_APP_HAVE_PROJECTS && collections.length > 0 ? (
					<Collections collections={collections} title="Our Collections" />
				) : null}
				{process.env.REACT_APP_HAVE_PROJECTS && isProjectsLoading ? (
					<Loading />
				) : process.env.REACT_APP_HAVE_PROJECTS && projects.length > 0 ? (
					<Projects projects={projects} title="Featured Projects" />
				) : null} */}
					{isTokenLoading ? (
						<Loading />
					) : <Listings withScratch={collection.withScratch} canFlip={collection.canFlip} tokens={tokens} collectionID={collectionID} title="" />
					}
				</Box>
			</div>
			{/* <MissionBlock /> */}
			<PartnersBlock />
			<Modal
				open={openVideoPopup}
				fullWidth={true}
				onClose={handleModalClose}
				content={<YoutubeVideoPlayer youtubeUrl={playingVideo} />}
			/>
		</>
	);
};

export default Home;
