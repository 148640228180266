import Web3 from 'web3'

export const loadWeb3 = () => new Promise(async (resolve, reject) => {
  try {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else {
      window.web3 = false;
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
    }

    resolve(true);
  } catch (e) {
    reject(e);
  }
});

export const detectNetworkAccount = () => new Promise(async (resolve, reject) => {
  const web3 = window.web3
  // Load account
  const accounts = await web3.eth.getAccounts()
  if (!accounts.length) {
    reject('Unable to find any account')
  }

  const networkId = await web3.eth.net.getId();

  resolve({
    account: accounts[0],
    networkId
  })
});