import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import TableView from "src/components/TableView";
import { useCategoryState } from "src/state/categories";
import Toolbar from "src/components/DefaultToolbar";
import Loading from "src/components/Loading";
import { useState } from "react";
import Modal from "src/components/Modal";
import CategoryForm from "src/components/category/CategoryForm";

const columns = [
  {
    field: "name",
    headerName: "Category Name",
    editable: false,
    sortable: true,
    width: "30%",
  },
  {
    field: "listingCount",
    headerName: "Items Listed",
  },
];

const Categories = () => {
  const {
    promised: isLoading,
    categories,
    getCategory,
    createCategory,
    removeCategory,
    updateCategory,
  } = useCategoryState();

  const [editingCategory, setEditingCategory] = useState(null);
  const [showCategoryForm, setShowCategoryForm] = useState(false);

  const updateRow = (rowID, updatedValue) => {
    if (rowID) updateCategory(rowID, updatedValue);
    else {
      createCategory(updatedValue);
    }
  };

  const deleteRow = (rowID) => {
    removeCategory(rowID);
  };

  const sortedCategories = isLoading ? [] : [...categories];

  sortedCategories.sort((a, b) =>
    a.name < b.name ? -1 : a.name > b.name ? 1 : 0
  );

  const handleAddNew = () => setShowCategoryForm(true);
  const handleEdit = (categoryID) => {
    const category = getCategory(categoryID);
    setEditingCategory(category);
    setShowCategoryForm(true);
  };
  const handleSave = (values) => {
    if (editingCategory) {
      updateCategory(editingCategory.id, {
        name: values.category_name,
        photo: values.category_photo ? values.category_photo : editingCategory.photo
      })
    } else {
      createCategory({
        name: values.category_name,
        photo: values.category_photo ? values.category_photo : ""
      })
    }

    handleModalClose();
  }
  const handleModalClose = () => {
    setEditingCategory(null);
    setShowCategoryForm(false);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Toolbar
                title="Manage Categories"
                showAddButton
                onClickAddButton={handleAddNew}
              />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div style={{ height: 400, width: "100%" }}>
                    <TableView
                      rows={sortedCategories}
                      columns={columns}
                      actionButtons={["edit", "delete"]}
                      onEdit={handleEdit}
                      onDelete={deleteRow}
                    />
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </Box>
      <Modal
        open={showCategoryForm}
        onClose={handleModalClose}
        title="Add Category"
        content={
          <CategoryForm onSave={handleSave} defaultData={editingCategory} />
        }
      />
    </>
  );
};

export default Categories;
