import { cachedVersion } from "src/helpers/file.helper";
import { useSettingState } from "src/state";

const Logo = props => {
	const { getSetting } = useSettingState();
	const logoURL = getSetting("website_logo");
	return (
		<img
			alt="Logo"
			src="https://pbw2023.foodstarter.io/images/pbw-logo.png"
			style={{ maxWidth: "100%" }}
			{...props}
		/>
	);
};

export default Logo;
