import { createState, useState, postpone, none } from "@hookstate/core";
import { useErrorState } from "src/state";
import {
  fetchCategories,
  addNewCategory,
  updateCategory as updateCategoryDB,
  deleteCategory,
  setupCategoryData,
  changeListingCount
} from "src/db/firebase/categories";

const categoriesState = createState(fetchCategories());
categoriesState.batch((state) => {
  if (state.promised) return postpone;
});

export function useCategoryState() {
  const state = useState(categoriesState);
  const { setErrorMessage } = useErrorState();

  return {
    get promised() {
      return state.promised;
    },
    get categories() {
      return !state.promised ? state.get() : [];
    },
    getCategory(categoryID) {
      const category = !state.promised
        ? state.get().find((item) => item.id === categoryID)
        : {};

      return category;
    },
    async createCategory(updatedData) {
      //send to db
      const dbID = await addNewCategory(updatedData);

      state.merge([setupCategoryData(dbID, updatedData)]);

      return dbID;
    },
    updateCategory(categoryID, updatedData) {
      if (!state.promised) {
        const idx = state.findIndex((i) => i.id.get() === categoryID);
        if (idx >= 0) {
          state[idx].merge(updatedData);
        }

        updateCategoryDB(categoryID, updatedData);
      }
    },
    removeCategory(categoryID) {
      const idx = state.value.findIndex((i) => i.id === categoryID);

      if (idx < 0 || state[idx].listingCount.get() > 0) {
        setErrorMessage("Can not delete a category that already got listings");
        return;
      }

      if (idx >= 0) {
        state[idx].set(none);
      }

      //send to db
      deleteCategory(categoryID);
    },
    updateCategoryItemCount(categoryID, changeBy = 1) {
      if (!state.promised) {
        const idx = state.findIndex((i) => i.id.get() === categoryID);
        if (idx >= 0) {
          state[idx].listingCount.set((p) => p + changeBy);
        }

        changeListingCount(categoryID, changeBy);
      }
    }
  };
}
