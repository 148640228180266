export const uploadVideo = (file, filename, mode = "blob") =>
	new Promise((resolve, reject) => {
		const data = new FormData();
		if (mode === "blob") data.append("file", file, filename);
		else data.append("file", file);
		data.append("upload_preset", process.env.REACT_APP_CLOUDINARY_VIDEO_PRESET);
		data.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);

		fetch("https://api.cloudinary.com/v1_1/" + process.env.REACT_APP_CLOUDINARY_CLOUD_NAME + "/video/upload", {
			method: "post",
			body: data,
		})
			.then(resp => resp.json())
			.then(data => {
				resolve(data.url);
			})
			.catch(err => {
				console.log(err);
				reject();
			});
	});

export const checkVideoExists = filename =>
	new Promise((resolve, reject) => {
		const url =
			"https://res.cloudinary.com/" +
			process.env.REACT_APP_CLOUDINARY_CLOUD_NAME +
			"/video/upload/videos/" +
			filename;

		fetch(url)
			.then(resp => {
				if (resp.status === 200) resolve();
				else reject();
			})
			.catch(err => {
				reject();
			});
	});
