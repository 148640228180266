import { useEffect, useRef, useState, useContext } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  Checkbox,
  FormControl,
  FormLabel,
  FormHelperText,
  InputAdornment,
  ListItemText,
  Typography,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useCategoryState, useProjectsState } from "src/state";
import { CURRENCIES } from "src/constants/Currencies";

const CollectionSettingsForm = ({ defaultData, onSave, ...props }) => {
  const { categories } = useCategoryState();
  const { promised: isProjectLoading, getProject } = useProjectsState();

  const [projectName, setProjectName] = useState(null);

  useEffect(() => {
    if (!isProjectLoading) {
      const project = getProject(defaultData.projectID);
      if (project) setProjectName(project.project_name);
    }
  }, [isProjectLoading])
  

  return (
    <Formik
      initialValues={{
        category: defaultData?.category ?? "",
        description: defaultData?.description ?? "",
        currencies: [],
        royalty: defaultData?.royalty ?? 0,
        youtube: defaultData?.youtube ?? "",
        unit: defaultData?.unit ?? "",
      }}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        onSave &&
          onSave({
            ...values,
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          {!process.env.REACT_APP_HAVE_PROJECTS && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <FormLabel sx={{ mb: 1 }}>Category</FormLabel>
              <Select
                fullWidth
                name="category"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.category}
              >
                {categories.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ mx: 0 }}>
                Adding a category will help make your item discoverable on
                Marketplace.
              </FormHelperText>
            </FormControl>
          )}
          {process.env.REACT_APP_HAVE_PROJECTS && projectName && <Typography variant="h4" mb={2}>Project: {projectName}</Typography>}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel>YouTube Video URL</FormLabel>
            <FormHelperText sx={{ mx: 0 }}>
              Can provide a video URL to let the users know more about items in
              this collection.
            </FormHelperText>
            <TextField
              fullWidth
              margin="normal"
              name="youtube"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.youtube}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel>Description</FormLabel>
            <TextField
              fullWidth
              multiline
              rows={4}
              margin="normal"
              name="description"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.description}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel>Unit per NFT</FormLabel>
            <TextField
              fullWidth
              margin="normal"
              name="unit"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.unit}
            />
          </FormControl>
          {/* <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel sx={{ mb: 1 }}>Payment tokens</FormLabel>
            <FormHelperText sx={{ mx: 0 }}>
              These tokens can be used to buy and sell your items.
            </FormHelperText>
            <Select
              fullWidth
              multiple
              renderValue={(selected) => selected.join(", ")}
              name="currencies"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.currencies}
            >
              {CURRENCIES.map((item, index) => (
                <MenuItem key={index} value={item.symbol}>
                  <Checkbox
                    checked={values.currencies.indexOf(item.symbol) > -1}
                  />
                  <ListItemText primary={item.symbol} />
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel>Royalties</FormLabel>
            <FormHelperText sx={{ mx: 0, mb: 1 }}>
              Collect a fee when a user re-sells an item you originally created.
              This is deducted from the final sale price and paid monthly to a
              payout address of your choosing.
            </FormHelperText>
            <TextField
              name="royalty"
              type="number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.royalty}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: { min: 0, max: 50, step: 0.00000001 },
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <Button
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Save
            </Button>
          </FormControl>
        </form>
      )}
    </Formik>
  );
};
export default CollectionSettingsForm;
