import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
} from "@material-ui/core";
import { isValidAddress } from "src/helpers/blockchain.helper";

const TransferForm = ({ onTransfer, ...props }) => {
  return (
    <Formik
      initialValues={{
        wallet_address: "",
      }}
      validationSchema={Yup.object().shape({
        wallet_address: Yup.string()
          .max(255)
          .required("Wallet Address is required")
          .test('is-valid', 'This address is not valid', isValidAddress),
      })}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        onTransfer &&
          onTransfer({
            walletAddress: values.wallet_address,
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormControl
            fullWidth
            component="fieldset"
          >
            <FormLabel sx={{ mb: 2 }} component="legend">
              Enter the wallet address you would like this token to be
              transferred to
            </FormLabel>
            <TextField
              error={Boolean(touched.wallet_address && errors.wallet_address)}
              fullWidth
              helperText={touched.wallet_address && errors.wallet_address}
              label="Wallet Address"
              name="wallet_address"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.wallet_address}
              variant="outlined"
              margin="normal"
            />
            <Button
              disabled={
                isSubmitting ||
                !touched.wallet_address ||
                (errors.wallet_address && errors.wallet_address.length > 0)
              }
              fullWidth
              size="large"
              variant="contained"
              type="submit"
            >
              Transfer
            </Button>
          </FormControl>
        </form>
      )}
    </Formik>
  );
};

export default TransferForm;
