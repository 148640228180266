import db from "src/plugins/Firebase";

const firestore_collection_name = "settings";

export const setupSettingData = (dbID, itemData) => {
  return {
    field: dbID,
    value: itemData.value,
  };
};

// Fetch Records
export const fetchSettings = async () => {
  const dbResponse = db.collection(firestore_collection_name);
  const dbData = await dbResponse.get();

  const data = [];

  dbData.docs.forEach((item) => {
    data.push(setupSettingData(item.id, item.data()));
  });

  return data;
};

export const saveSetting = async (field, value) => {
  var docRef = db.collection(firestore_collection_name).doc(field);

  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        db.collection(firestore_collection_name).doc(field).update({
          value,
        });
      } else {
        db.collection(firestore_collection_name).doc(field).set({
          value,
        });
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};

export const deleteSetting = async (field) => {
  db.collection(firestore_collection_name)
    .doc(field)
    .delete();
}