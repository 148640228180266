import db, { FirebaseFieldValue } from "src/plugins/Firebase";

const firestore_collection_name = "projects";

export const setupProjectData = (id, data) => {
  return { id, ...data };
};

// Fetch Records
export const fetchProjects = async () => {
  const dbResponse = db.collection(firestore_collection_name);
  const dbData = await dbResponse.get();

  const data = [];

  dbData.docs.forEach((item) => {
    data.push(setupProjectData(item.id, item.data()));
  });

  return data;
};

export const fetchProject = async (projectID) => {
  const dbData = await db
    .collection(firestore_collection_name)
    .doc(projectID)
    .get();

  return setupProjectData(dbData.id, dbData.data());
};

export const addNewProject = async (data) => {
  let dbID = null;

  await db
    .collection(firestore_collection_name)
    .add(data)
    .then((docRef) => {
      dbID = docRef.id;
    });

  return dbID;
};

export const updateProject = async (id, data) => {
  db.collection(firestore_collection_name).doc(id).update(data);
};

export const incrementCollectionCount = async (id) => {
  db.collection(firestore_collection_name)
    .doc(id)
    .update({ collection_count: FirebaseFieldValue.increment(1) });
};

export const deleteProject = async () => {};
