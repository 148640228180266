import React, { Component } from 'react';

class DragAndDrop extends Component {
  state = {
    drag: false,
    error: false
  }
  dragCounter = 0;
  dropRef = React.createRef()
  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true })
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        const item = e.dataTransfer.items[i];
        if (item.kind === 'file') {
          if (!item.type.match('^image/')) {
            this.setState({ error: true })
          }
        } else {
          this.setState({ error: true })
        }
      }
    } else
      this.setState({ error: false })
  }

  handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.setState({ drag: false, error: false })
    }
  }
  handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ drag: false, error: false })
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files)
      try {
        e.dataTransfer.clearData()
      } catch (error) {

      }
      this.dragCounter = 0
    }
  }
  componentDidMount() {
    let div = this.dropRef.current
    div.addEventListener('dragenter', this.handleDragIn)
    div.addEventListener('dragleave', this.handleDragOut)
    div.addEventListener('dragover', this.handleDrag)
    div.addEventListener('drop', this.handleDrop)
  }
  componentWillUnmount() {
    let div = this.dropRef.current
    div.removeEventListener('dragenter', this.handleDragIn)
    div.removeEventListener('dragleave', this.handleDragOut)
    div.removeEventListener('dragover', this.handleDrag)
    div.removeEventListener('drop', this.handleDrop)
  }
  render() {
    return (
      <div
        style={{ width: 'max-content', border: this.state.drag ? '4px dashed #AAA' : '2px dashed #CCC', backgroundColor: this.state.error ? 'rgba(255,0,0,.05)' : 'rgba(0,0,0,.05)' }}
        ref={this.dropRef}
      >
        {this.props.children}
      </div>
    )
  }
}

export default DragAndDrop