import { Box, Typography } from "@material-ui/core";
import Tabs from "src/components/Tabs";
import CopyToClipboard from "src/components/CopyToClipboard"
import { shortAccount } from "src/helpers/blockchain.helper";

const ViewUserDetails = ({ user }) => {
  return (
    <Tabs>
      <Box label={`User Details`}>
        <Box my={1}>
          <Typography variant="caption" color="textSecondary">
            Username
          </Typography>
          <Typography variant="h4">{user.username}</Typography>
        </Box>
        <Box my={1}>
          <Typography variant="caption" color="textSecondary">
            Email
          </Typography>
          <Typography variant="h4">{user.email}</Typography>
        </Box>
        {user.bio && (
          <Box my={1}>
            <Typography variant="caption" color="textSecondary">
              Bio
            </Typography>
            <Typography variant="h4">{user.bio}</Typography>
          </Box>
        )}
        <Box my={1}>
          <Typography variant="caption" color="textSecondary">
            Wallet Address
          </Typography>
          <CopyToClipboard text={shortAccount(user.id)} copyText={user.id} />
        </Box>        
      </Box>
      <Box label={`Favourites`}>
        <Typography variant="body2">No items added to favourites</Typography>
      </Box>
      <Box label={`Collections`}>
        <Typography variant="body2">No collections added</Typography>
      </Box>
    </Tabs>
  );
};

export default ViewUserDetails;
