import { TextField } from "@material-ui/core";
import { useState } from "react";

const EditableText = ({ text, onChange }) => {
  const [inEditMode, setInEditMode] = useState(text == "");
  const [value, setValue] = useState(text);

  const handleBlur = (event) => {
    if (!value || value.trim() === "") return;

    setInEditMode(false);
    onChange && onChange(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  if (!inEditMode)
    return <span onClick={() => setInEditMode(true)}>{value}</span>;

  return (
    <TextField
      type="text"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      sx={{ width: "90%" }}
    />
  );
};

export default EditableText;
