import { createState, useState } from "@hookstate/core";
import { logout as firebaseLogout } from "src/plugins/FirebaseAuth";

import { fetchUserProfile as dbFetchUserProfile, saveUserProfile } from "src/db/firebase/users";

// get from localstorage if previously logged in
const persistantState = JSON.parse(localStorage.getItem("loggedInAs"));

const defaultSessionState = persistantState
  ? persistantState
  : {
      user: null,
    };

const sessionState = createState({ ...defaultSessionState });

export function useSessionState() {
  const state = useState(sessionState);

  return {
    get loggedInUser() {
      return state.user.value;
    },
    get profile() {
      // alias for logged in user
      return state.user.value;
    },
    set loggedInUser(user) {
      state.merge({ user: { ...user } });

      //set to localstorage
      localStorage.setItem("loggedInAs", JSON.stringify(state.value));
    },
    setProfile(userID, data) {
      this.loggedInUser = { ...data, userID };

      //save to db
      saveUserProfile(userID, data);
    },
    async fetchUserProfile(userID) {
      const profile = await dbFetchUserProfile(userID);
      return profile;
    },
    logout() {
      state.set({ user: null });

      firebaseLogout();

      //set to localstorage
      localStorage.setItem("loggedInAs", JSON.stringify(state.value));
    },
  };
}
