import { colors } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      default: "#F4F6F8",
      paper: colors.common.white,
      hero: "#9c9082",
    },
    primary: {
      contrastText: "#ffffff",
      main: "#9c9082",
    },
    text: {
      primary: "#000",
      secondary: "#6b778c",
    },
  },
  shadows,
  typography,
});

export default theme;
