import { Box, Container, Typography } from "@material-ui/core";
import { KeyboardReturnOutlined } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Loading from "src/components/Loading";
import ProjectForm from "src/components/projects/ProjectForm";
import { admin_link } from "src/helpers/links.helper";
import { useCategoryState, useProjectsState } from "src/state";

const Form = () => {
  const { id: projectID } = useParams();
  const navigate = useNavigate();
  const {
    promised: loadingProjects,
    createProject,
    updateProject,
    getProject,
  } = useProjectsState();
  const { updateCategoryItemCount } = useCategoryState();
  const [defaultData, setDefaultData] = useState();
  const [showForm, setShowForm] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);

  useEffect(() => {
    if (projectID && !loadingProjects) {
      const data = getProject(projectID);
      if (data) {
        setDefaultData({ ...data });
      }
      setShowForm(true);
    } else if (!projectID) {
      setShowForm(true);
    }
  }, [projectID, loadingProjects]);

  const handleProjectSave = async (values) => {
    setIsSavingData(true);

    if (projectID) {
      //check if category changed
      if (defaultData.category !== values.category) {
        defaultData.category && updateCategoryItemCount(defaultData.category, -1);
        values.category && updateCategoryItemCount(values.category);
      }

      await updateProject(projectID, values);
    } else {
      await createProject(values);
      values.category && updateCategoryItemCount(values.category);
    }

    setIsSavingData(false);

    navigate(admin_link("/projects"));
  };
  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Box mb={3}>
          <Typography variant="h2" mr={1} color="textPrimary">
            {projectID ? "Edit" : "New"} Project
          </Typography>
        </Box>
        {!loadingProjects && showForm && (
          <ProjectForm
            onSave={handleProjectSave}
            defaultData={
              defaultData && defaultData.project_name
                ? { ...defaultData }
                : null
            }
          />
        )}
        {isSavingData && <Loading />}
      </Container>
    </Box>
  );
};

export default Form;
