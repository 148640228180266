import { useState, useContext } from "react";
import {
  Box,
  Container,
  Grid,
  Button,
  Paper,
  Typography,
} from "@material-ui/core";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import Toolbar from "src/components/collection/CollectionToolbar";
import CollectionCard from "src/components/collection/Card";
import Modal from "src/components/Modal";
import CollectionForm from "src/components/collection/CollectionForm";
import Loading from "src/components/Loading";
import { useCollectionState, useProjectsState } from "src/state";
import { cerateAndDeployCollection } from "src/helpers/collections.helper";

const CollectionsPage = () => {
  const blockchainInfo = useContext(BlockchainContext);
  const {
    promised: isLoading,
    collections,
    createCollection,
    removeCollection,
  } = useCollectionState();

  const { incrementCollectionCount } = useProjectsState();

  const [showAddCollectionForm, setShowAddCollectionForm] = useState(false);
  const [filterKeyword, setFilterKeyword] = useState("");
  const [isSavingData, setIsSavingData] = useState(false);

  const handleAddCollection = async () => {
    if (blockchainInfo && blockchainInfo.account) {
      setShowAddCollectionForm(true);
    } else if (blockchainInfo) {
      blockchainInfo.connectToBlockchain();
    }
  };
  const handleModalClose = () => setShowAddCollectionForm(false);
  const filterCollections = (keyword) => setFilterKeyword(keyword);
  const handleOnCreateCollection = async (data) => {
    setShowAddCollectionForm(false);

    setIsSavingData(true);

    cerateAndDeployCollection(data, createCollection, removeCollection)
      .then(() => {
        //update project collection count if, added to project
        if (data.projectID) {
          incrementCollectionCount(data.projectID);
        }
      })
      .finally(() => setIsSavingData(false));
  };

  const listings = collections
    .filter((collection) => {
      const filterBy = new RegExp(filterKeyword, "i");
      return (
        filterKeyword === "" ||
        collection.name.search(filterBy) >= 0 ||
        collection.symbol.search(filterBy) >= 0 ||
        (collection.owner && collection.owner.search(filterBy) >= 0) ||
        collection.address.search(filterBy) >= 0
      );
    })
    .filter(
      (collection) =>
        !blockchainInfo.networkId ||
        collection.chainID === blockchainInfo.networkId
    );

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          {isSavingData && <Loading />}
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Toolbar
                onPressAddButton={() => handleAddCollection()}
                onSearch={filterCollections}
              />
              <Box sx={{ pt: 3 }}>
                <Grid container spacing={3}>
                  {listings.map((collection) => (
                    <Grid item key={collection.address} lg={4} sm={6} xs={12}>
                      <CollectionCard collection={collection} />
                    </Grid>
                  ))}
                  {listings.length === 0 && (
                    <Grid item xs={12}>
                      <Paper sx={{ py: 3 }} align="center">
                        <Typography variant="h3" align="center">
                          No Collections Found
                        </Typography>
                        <Button onClick={handleAddCollection}>Add Now</Button>
                      </Paper>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </>
          )}
          {/* <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: 3
            }}
          >
            <Pagination
              color="primary"
              count={3}
              size="small"
            />
          </Box> */}
        </Container>
      </Box>
      <Modal
        open={showAddCollectionForm}
        onClose={handleModalClose}
        title="Add Collection"
        content={<CollectionForm onCreate={handleOnCreateCollection} />}
      />
    </>
  );
};

export default CollectionsPage;
