export const NETWORKS = [
  {
    id: 1,
    name: "Ethereum (Mainnet)",
    baseURL: "https://etherscan.io/",
  },
  {
    id: 3,
    name: "Ropsten Test Network",
    baseURL: "https://ropsten.etherscan.io/",
    currencies: ["WETH"],
  },
  {
    id: 4,
    name: "Rinkeby Test Network",
    baseURL: "https://rinkeby.etherscan.io/",
    currencies: ["USDT"],
  },
  {
    id: 5,
    name: "Goerli Test Network",
  },
  {
    id: 42,
    name: "Kovan Test Network",
  },
  {
    id: 56,
    name: "Binance Smart Chain",
    baseURL: "https://bscscan.com",
    currencies: ["WETH", "BUSD", "TRON"],
  },
  {
    id: 97,
    name: "Binance (Testnet)",
    baseURL: "https://testnet.bscscan.com",
    currencies: ["MUSD"],
  },
  {
    id: 137,
    name: "Matic (Mainnet)",
    baseURL: "https://polygonscan.com/",
    currencies: ["WETH", "USDT"],
  },
  {
    id: 80001,
    name: "Matic Test Network",
    baseURL: "https://mumbai.polygonscan.com/",
    currencies: ["DERC20", "TST", "MATIC"],
  },
];
