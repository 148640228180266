import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core';

const BareLayoutRoot = experimentalStyled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const BareLayoutWrapper = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const BareLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const BareLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
});

const BareLayout = () => (
  <BareLayoutRoot>
    <BareLayoutWrapper>
      <BareLayoutContainer>
        <BareLayoutContent>
            <Outlet />
        </BareLayoutContent>
      </BareLayoutContainer>
    </BareLayoutWrapper>
  </BareLayoutRoot>
);

export default BareLayout;
