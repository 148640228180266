import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  //databaseURL: process.env.,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.envREACT_APP_FIREBASE_MESSEGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  //measurementId: process.env.
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

// export const increment = firebase.firestore.FieldValue.increment(1);
// export const decrement = firebase.firestore.FieldValue.decrement(1);

export const storage = firebase.storage();
export const storageURL = `https://storage.googleapis.com/${firebaseConfig.storageBucket}/`;

export const FirebaseFieldValue = firebase.firestore.FieldValue;
export default db;
