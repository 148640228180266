import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import SearchBar from 'src/components/SearchBar';

const _Toolbar = ({ onPressAddButton, onSearch, ...props }) => (
  <Box {...props}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <Typography variant="h2" color="textPrimary" gutterBottom>Collections</Typography>
      <Button
        color="primary"
        variant="contained"
        onClick={onPressAddButton}
      >
        Add Collection
      </Button>
    </Box>
    <Box sx={{ mt: 3 }}>
      <Card>
        <CardContent style={{ paddingBottom: 16 }}>
          <SearchBar sx={{ maxWidth: 500 }} placeholder="Search Collection" onSearch={onSearch} />
        </CardContent>
      </Card>
    </Box>
  </Box>
);

export default _Toolbar;
