import { Box, Container } from "@material-ui/core";

const AdminPageContainer = ({children}) => {
  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        {children}
      </Container>
    </Box>
  );
};

export default AdminPageContainer;