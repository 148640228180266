import { Outlet, useLocation } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import MainNavbar from "./components/MainNavbar";
import MainFooter from "./components/MainFooter";
import theme from "src/themes/default";
import "./css/styles/style.css";
import { useSettingState } from "src/state";
import { cachedVersion } from "src/helpers/file.helper";
import { Box } from "@material-ui/core";

const MainLayout = () => {
  const location = useLocation();
  const {promised: isLoadingSettings, getSetting} = useSettingState();
  // useEffect(() => {
  //   if (!isLoadingSettings) {
  //     const favicon_url = getSetting('website_icon');
  //     if (favicon_url) {
  //       const favicon = document.getElementById("favicon");
  //       favicon.href = favicon_url;
  //     } 
  //   }
  // }, [isLoadingSettings])
  return isLoadingSettings ? null : (
    <ThemeProvider theme={theme}>
      <Box id="main-wrapper" className={`front page-${location.pathname === '/' ? 'home' : 'inner'}`}>
        <MainNavbar />
        <Box>
          <Box>
            <Box>
              <Outlet />
            </Box>
          </Box>
        </Box>
        <MainFooter />
      </Box>
    </ThemeProvider>
  );
};

export default MainLayout;
