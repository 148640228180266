import React from 'react';
import { FastField } from "formik";
import { TextField } from '@material-ui/core';

// const FormikTextField = ({ error, helperText, ...props }) => {
//   const [field, meta] = useField(props);
//   const { values, submitForm } = useFormikContext();

//   console.log(values);

//   return <TextField
//     {...props}
//     error={meta.error}
//   />
// }

const FormikTextField = ({ name, error, helperText, ...props }) => (
  <FastField name={name} placeholder="F">
    {({ field, form, meta }) => (
      <TextField
        {...props}
        {...field}
        error={Boolean(meta.touched && meta.error)}
        helperText={(meta.touched && meta.error) || helperText}
      />
    )}
  </FastField>
)

export default FormikTextField;