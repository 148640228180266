//import Md5Hash from "src/plugins/Md5Hash";

export function truncate(fullStr, strLen, separator) {
  if (fullStr.length <= strLen) return fullStr;

  separator = separator || "...";

  var sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return (
    "0x" +
    fullStr.replace("0x", "").substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr.length - backChars)
  );
}

export function toSnakeCase(camleCaseString) {
  return camleCaseString.replace(
    /[A-Z]/g,
    (letter) => `_${letter.toLowerCase()}`
  );
}

export function toTitleText(snakeCaseString) {
  return snakeCaseString
    .split("_")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function toSlug(string) {
  return string.replace(/ /g, '_').toLowerCase();
}

// export function toHash(string) {
//   // for now useing md5 hash, can be changed
//   // to sha256 later if needed

//   return Md5Hash(string);
// }