import { Box, Container, Grid, Typography } from "@material-ui/core";

const Dashboard = () => (
  <>
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h1">Welcome!</Typography>
            <Typography color="textSecondary" variant="body2">
              This section will show various statistics for collections and
              marketplace
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
);

export default Dashboard;
