import { deploy, mint } from "src/plugins/Ethereum";
import { getValidOrEmptyAddress } from "src/helpers/blockchain.helper";
import { hash_to_ipfs_link } from "./links.helper";

export const cerateAndDeployCollection = (data, fnCreateCollection, fnRemoveCollection) =>
	new Promise(async (resolve, reject) => {
		// add to collection state
		const dbID = await fnCreateCollection({ ...data, pending: true });

		try {
			//deploy the contract and set data.address
			deploy(data.contract_type, [
				data.name,
				data.symbol,
				getValidOrEmptyAddress(data.marketplaceAddress),
				Math.round(parseFloat(data.royalty) * 100000000).toString(),
			])
				.then(result => {
					data.address = result.address;
					data.tx = result.tx;

					// create a new one, using address
					fnCreateCollection(data);

					resolve();
				})
				.catch(error => {
					reject();
				})
				.finally(() => fnRemoveCollection(dbID));
		} catch (e) {
			console.log(e);
			reject();
		}
	});

export const mintToken = (data, collection, blockchainInfo, fnCreateToken, fnUpdateToken, fnDeleteFailedToken) =>
	new Promise(async (resolve, reject) => {
		const collectionID = collection.address;

		const dbID = await fnCreateToken(
			collectionID,
			{
				tokenID: null,
				sku: data.item_sku,
				name: data.item_name,
				image_url: data.image_url,
				animation_url: data.animation_url ?? "",
				animation_file_type: data.animation_file_type ?? "",
				metadata_url: hash_to_ipfs_link(data.metadata_hash),
				// certificate_url: data.certificate_hash
				//   ? hash_to_ipfs_link(data.certificate_hash)
				//   : "",
				minter: blockchainInfo.account,
				owner: data.wallet_address && data.wallet_address != "" ? data.wallet_address : blockchainInfo.account,
			},
			data.metadata
		);

		resolve();

		let options = {
			sku: data.item_sku,
			metadata_url: hash_to_ipfs_link(data.metadata_hash),
		};

		if (data.wallet_address && data.wallet_address != "") options.send_to_address = data.wallet_address;

		mint(collection.contract_type, collection.address, options)
			.then(tokenID => {
				// after MINT successfully save to DB
				fnUpdateToken(collectionID, dbID, {
					tokenID: tokenID,
				});
			})
			.catch(e => {
				fnDeleteFailedToken(collectionID, dbID);
				//remove added token from db
			});
	});
