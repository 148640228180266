import Button from "@material-ui/core/Button";
import { useGlobalState } from "src/state";

const TransferButton = ({ tokenInfo }) => {
  const { toggleTransferModalVisible, setTransferForToken } = useGlobalState();

  const initTransfer = () => {
    toggleTransferModalVisible();
    setTransferForToken(tokenInfo);
  };

  return (
    <>
      <Button
        sx={{ ml: 2 }}
        variant="contained"
        color="success"
        onClick={() => initTransfer()}
        size="small"
      >
        Transfer
      </Button>
    </>
  );
};

export default TransferButton;
