import moment from "moment";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Listings from "src/components/frontend/Listings";
import Modal from "src/components/Modal";

import { useCollectionState, useCategoryState, useTokenState, useListingState } from "src/state";
import Loading from "src/components/Loading";

import { shortAccount, tokenLinkURL, networkName } from "src/helpers/blockchain.helper";
import ListingViewOnBlock from "src/components/frontend/partials/ListingViewOnBlock";
import { ViewOnChainIcon } from "src/components/frontend/icons";
import Tabs from "src/components/Tabs";
import { youtube_thumbnail_link } from "src/helpers/links.helper";
import YoutubeVideoPlayer from "src/components/frontend/YoutubeVideoPlayer";

import emptyIcon from "src/themes/default/assets/img/empty-list-bg.png";
import { BlockchainContext } from "src/providers/BlockchainProvider";

const CollectionContainer = styled("div")(({ theme }) => ({
	padding: "50px 0",
}));

const Collection = () => {
	const { cid: collectionID } = useParams();
	const { promised: isCollectionLoading, getCollection } = useCollectionState();
	const { promised: isTokenLoading, tokens } = useTokenState(collectionID);
	const { promised: isListingLoading, listings: allListings } = useListingState();
	const navigate = useNavigate();

	const blockchainInfo = useContext(BlockchainContext);

	const [openVideoPopup, setOpenVideoPopup] = useState(false);
	const [playingVideo, setPlayingVideo] = useState(false);

	const { promised: isCategoriesLoading, getCategory, categories } = useCategoryState();

	const collection = getCollection(collectionID);

	if (!collection) {
		navigate("/404", { replace: true });
		return null;
	}

	const listingCategory = collection.categoryID && !isCategoriesLoading ? getCategory(collection.categoryID) : null;

	const listings = isListingLoading
		? []
		: allListings.filter(item => item.collectionID === collectionID && !item.purchased);

	const openVideo = videoURL => {
		setPlayingVideo(videoURL);
		setOpenVideoPopup(true);
	};

	const handleModalClose = () => {
		setOpenVideoPopup(false);
		setPlayingVideo(false);
	};

	const AddTokenButton = () => {
		return (
			blockchainInfo &&
			blockchainInfo.account === collection.owner &&
			(blockchainInfo.networkId === collection.chainID ? (
				<Button variant="contained" onClick={() => navigate("add-item")}>
					{tokens.length ? "Add More" : "Add Now"}
				</Button>
			) : (
				<Typography variant="h6">
					Connect to <strong>"{networkName(collection.chainID)}"</strong> to add items under this collection
				</Typography>
			))
		);
	};

	return (
		<>
			<div className="page-title">
				<div className="container">
					<div className="row align-items-center justify-content-between">
						<div className="col-12">
							<div className="page-title-content">
								<h3>Our Collections</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
			{isCollectionLoading ? (
				<Loading />
			) : (
				<CollectionContainer className="collection-contianer">
					<Box className="container">
						<Grid container spacing={2}>
							<Grid item xs={12} md={6} lg={4}>
								<div className="css-vurnku">
									<div className="css-8zdpe8">
										<div className="css-pk1a08">
											<div className="css-f19vuz">
												<h2 className="css-1cce5m9" style={{ marginTop: 0 }}>
													{collection.name} ({collection.symbol})
												</h2>
												<p
													style={{
														fontSize: 12,
														marginTop: 5,
														fontStyle: "italic",
													}}
												>
													ERC{collection.contractType} collection created in{" "}
													{moment(collection.createdAt).format("MMM YYYY")} on{" "}
													{networkName(collection.chainID)}
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="css-4cffwv" style={{ marginBottom: "30px" }}>
									<div className="css-vurnku">
										<div className="css-1jm49l2" aria-expanded="false">
											<a
												className="css-h6hd16"
												href={tokenLinkURL(collection.chainID, collection.address)}
												target="_blank"
											>
												<div className="css-1fp3cxy">
													<div className="css-4cffwv">
														<div className="username-tag css-5uuy7">
															{shortAccount(collection.address)}
														</div>
													</div>
												</div>
											</a>
										</div>
									</div>
								</div>
								<div className="css-1g4f2k1">
									{collection.youtube && (
										<div className="css-vurnku">
											<div className="css-ofwg41">Videos</div>
											<div className="css-5jvy1z">
												<a
													href="javascript:void()"
													onClick={() => openVideo(collection.youtube)}
												>
													<img src={youtube_thumbnail_link(collection.youtube)} />
												</a>
											</div>
										</div>
									)}
									{collection.description && (
										<div className="css-vurnku">
											<div className="css-ofwg41">Description</div>
											<Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
												{collection.description}
											</Typography>
										</div>
									)}
									<div className="css-vurnku">
										<div className="css-ofwg41">Total Supply</div>
										<div className="css-5jvy1z">
											{collection.totalItems} {collection.unit ? collection.unit : "Boxes"}
										</div>
										{process.env.REACT_APP_ALLOW_ADD_COLLECTION && tokens.length > 0 && (
											<AddTokenButton />
										)}
									</div>
									{collection.categoryID && listingCategory && (
										<div className="css-vurnku">
											<div className="css-ofwg41">Category</div>
											<div className="css-5jvy1z">{listingCategory.name}</div>
										</div>
									)}
									<ListingViewOnBlock
										linkTo={tokenLinkURL(collection.chainID, collection.address)}
										title={`View on ${networkName(collection.chainID)}`}
										icon={ViewOnChainIcon}
									/>
								</div>
							</Grid>
							<Grid item xs={12} md={6} lg={8}>
								{collection.bannerImage && <img src={collection.bannerImage} alt={collection.name} style={{ width: "100%" }} />}
								{isTokenLoading ? (
									<Loading />
								) : !tokens || !tokens.length ? (
									<Box sx={{ textAlign: "center" }}>
										<img src={emptyIcon} style={{ marginBottom: 32 }} />
										<Typography variant="h3" align="center" mb={3}>
											There are no items available in this collection!
										</Typography>
										<AddTokenButton />
									</Box>
								) : (
									<Listings withScratch={collection.withScratch} canFlip={collection.canFlip} tokens={tokens} collectionID={collectionID} title="" />
								)}
							</Grid>
						</Grid>
					</Box>
					<Modal
						open={openVideoPopup}
						fullWidth={true}
						onClose={handleModalClose}
						content={<YoutubeVideoPlayer youtubeUrl={playingVideo} />}
					/>
				</CollectionContainer>
			)}
		</>
	);
};

export default Collection;
