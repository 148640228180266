import {
  Box,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const _SearchBar = ({ placeholder, onSearch, ...props }) => {
  const handleChange = (e) => onSearch && onSearch(e.target.value);

  return (
    <Box {...props}>
      <TextField
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon
                fontSize="small"
                color="action"
              >
                <SearchIcon />
              </SvgIcon>
            </InputAdornment>
          )
        }}
        placeholder={placeholder ?? "Search Product"}
        variant="outlined"
        onChange={handleChange}
      />
    </Box>
  );
}

export default _SearchBar;