import React, { useState } from "react";
import VineArtForm from "src/components/collection/item-forms/VineArt";
import FoodStarterForm from "src/components/collection/item-forms/FoodStarter";
import UniversalForm from "src/components/collection/item-forms/Universal";

const ItemForm = ({...props}) => {
	return process.env.REACT_APP_SITENAME === "VineArt.org" ? (
		<VineArtForm {...props} />
	) : process.env.REACT_APP_SITENAME === "FoodStarter" ? (
		<FoodStarterForm {...props} />
	) : (
		<UniversalForm {...props} />
	);
};

export default ItemForm;
