import { createState, useState } from "@hookstate/core";

const defaultErrorState = {
	showError: false,
	errorMessage: "",
	type: "error",
};

const errorState = createState({ ...defaultErrorState });

export function useErrorState() {
	const state = useState(errorState);

	return {
		get isError() {
			return state.showError.value;
		},
		get errorMessage() {
			return state.errorMessage.value;
		},
		get errorTitle() {
			return state.type.value === "success" ? "Success!" : "Sorry!";
		},
		setErrorMessage(message, type = "error") {
			state.set({
				showError: true,
				errorMessage: message,
				type,
			});
		},
		clearError() {
			state.set({ ...defaultErrorState });
		},
	};
}
