import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditableText from "src/components/EditableText";

const TableView = ({ rows, columns, actionButtons, onUpdate, onDelete, onEdit }) => {
  const updateRow = (rowID, updated) => {
    if (!updated.value || updated.value == "") return;

    const updatedField = {};
    updatedField[updated.field] = updated.value;
    onUpdate && onUpdate(rowID, updatedField);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.field} sx={{ width: column.width }}>
                {column.headerName}
              </TableCell>
            ))}
            {actionButtons && actionButtons.length > 0 && (
              <TableCell></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              hover
              key={row.id ? row.id : "new-category-" + rows.length}
            >
              {columns.map((column) => (
                <TableCell key={row.id + column.field}>
                  {column.editable ? (
                    <EditableText
                      text={row[column.field]}
                      onChange={(updatedValue) =>
                        updateRow(row.id, {
                          field: column.field,
                          value: updatedValue,
                        })
                      }
                    />
                  ) : (
                    row[column.field]
                  )}
                </TableCell>
              ))}
              {actionButtons && actionButtons.length > 0 && (
                <TableCell align="right">
                  {actionButtons.map((type) => {
                    switch (type) {
                      case "edit":
                        return row.id ? (
                          <Button
                            variant="contained"
                            onClick={() => onEdit(row.id)}
                            key={`edit-${row.id}`}
                          >
                            {type}
                          </Button>
                        ) : null;
                        break;
                      case "delete":
                        return row.id ? (
                          <IconButton
                            onClick={() => onDelete(row.id)}
                            key={`delete-${row.id}`}
                          >
                            <DeleteIcon />
                          </IconButton>
                        ) : null;
                    }
                  })}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableView;
