import { Box, Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/system";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ItemForm from "src/components/collection/ItemForm";
import { mintToken } from "src/helpers/collections.helper";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { useTokenState, useCollectionState } from "src/state";

const AddTokenContainer = styled("div")(({ theme }) => ({
	padding: "50px 0",
	maxWidth: "800px",
	margin: "0 auto",
}));

const AddToken = () => {
	const project = null;

	const { cid: collectionID } = useParams();

	const { promised: isCollectionLoading, getCollection } = useCollectionState();
	const {
		promised: isTokenLoading,
		getTokenBySku,
		createToken,
		updateToken,
		deleteFailedToken,
	} = useTokenState(collectionID);

	const collection = getCollection(collectionID);
	const blockchainInfo = useContext(BlockchainContext);

	const navigate = useNavigate();

	const [isSavingData, setIsSavingData] = useState(false);

	const validateSkuBeforeAdd = item_sku => {
		const exists = getTokenBySku(collectionID, item_sku);
		if (exists) return false;

		return true;
	};

	const handleCreateItem = async data => {
		setIsSavingData(true);

		await mintToken(data, collection, blockchainInfo, createToken, updateToken, deleteFailedToken);

		navigate('/collection/'+collectionID);
	};

	return (
		<AddTokenContainer>
			<Box className="container">
				<Card>
					<CardContent>
						<Typography variant="h1">Create New Item</Typography>
						<ItemForm
							toValidateSku={validateSkuBeforeAdd}
							onCreateItem={handleCreateItem}
							defaultData={
								collection && collection.itemCreationData?.item_name
									? collection.itemCreationData
									: null
							}
							projectData={project && project.project_name ? project : null}
							isForFrontend={true}
						/>
					</CardContent>
				</Card>
			</Box>
		</AddTokenContainer>
	);
};

export default AddToken;
