import { Typography } from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import ContentCopyIcon from "@material-ui/icons/ContentCopy";

import Menu from "src/components/PopperMenu";

const ShareButton = ({ title = "Share" }) => {
  const shareWith = (target) => {
    const ahref = window.location.href
    const encodedAhref = encodeURIComponent(ahref)

    let link = "";

    switch (target) {
      case "facebook":
        link = `https://www.facebook.com/sharer/sharer.php?u=${ahref}`
        open(link)
        break

      case "twitter":
        link = `https://twitter.com/intent/tweet?url=${encodedAhref}`
        open(link)
        break

      case "copy":
        navigator.clipboard.writeText(ahref)
        break

      default:
        break
    }
  };

  const open = socialLink => {
    window.open(socialLink, "_blank")
  }

  return (
    <Menu
      variant="contained"
      title={
        <>
          <ShareIcon />
          <Typography mx={1}>{title}</Typography>
        </>
      }
      options={[
        {
          icon: FacebookIcon,
          text: "Facebook",
          action: () => shareWith("facebook"),
        },
        {
          icon: TwitterIcon,
          text: "Twitter",
          action: () => shareWith("twitter"),
        },
        {
          icon: ContentCopyIcon,
          text: "Copy Link",
          action: () => shareWith("copy"),
        },
      ]}
    />
  );
};

export default ShareButton;
