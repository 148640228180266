import { useState } from "react";
import { useUsersState } from "src/state";
import { Box, Container } from "@material-ui/core";
import Loading from "src/components/Loading";
import ListView from "src/components/ListingTable";
import Toolbar from "src/components/users/UsersToolbar";
import Modal from "src/components/Modal";
import ViewUserDetails from "src/components/users/ViewUserDetails";

const Users = () => {
  const { promised: isLoading, users, getUser } = useUsersState();
  const [filterKeyword, setFilterKeyword] = useState("");
  const [userDetails, setUserDetails] = useState(null);

  const filterUsers = (keyword) => setFilterKeyword(keyword);

  const handleViewUser = (id) => {
    setUserDetails(getUser(id));
  }

  const handleModalClose = () => {
    setUserDetails(null);
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Toolbar
                onSearch={filterUsers}
              />
              <Box sx={{ pt: 3 }}>
                <ListView
                  items={users
                    .filter((user) => {
                      const filterBy = new RegExp(filterKeyword, "i");
                      return (
                        filterKeyword === "" ||
                        user.id.search(filterBy) >= 0 ||
                        user.username.search(filterBy) >= 0 ||
                        user.email.search(filterBy) >= 0
                      );
                    })}
                  fields={[
                    { key: "id", title: "Wallet Address" },
                    { key: "username", title: "Username" },
                    { key: "email", title: "Email" },
                  ]}
                  isSelectable={false}
                  customClasses={[{ anchor: "id" }]}
                  onRowClick={handleViewUser}
                />
              </Box>
            </>
          )}
        </Container>
      </Box>
      <Modal
        open={userDetails}
        onClose={handleModalClose}
        content={
          <ViewUserDetails user={userDetails} />
        }
      />
    </>
  );
};

export default Users;
