import { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Box, Button, TextField } from "@material-ui/core";
import Modal from "src/components/Modal";
import { isValidAddress } from "src/helpers/blockchain.helper";
import { useCollectionState } from "src/state/collections";

const FormContractAddress = ({ collection, onSave }) => {
  const { getCollection, createCollection, removeCollection } =
    useCollectionState();

  return (
    <Formik
      initialValues={{
        contract_address: "",
      }}
      validationSchema={Yup.object().shape({
        contract_address: Yup.string()
          .max(255)
          .required("Contract Address is required")
          .test(
            "contract_address",
            "Doesnt seems to be a valid Address",
            isValidAddress
          )
          .test(
            "contract_address",
            "This contract address has already been added",
            function (address) {
              const exists = getCollection(address);
              if (exists) return false;

              return true;
            }
          ),
      })}
      onSubmit={(values, actions) => {
        createCollection({
          ...collection,
          address: values.contract_address,
          pending: false,
        }).then(() => removeCollection(collection.address));

        actions.setSubmitting(false);
        onSave && onSave();
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.contract_address && errors.contract_address)}
            fullWidth
            helperText={touched.contract_address && errors.contract_address}
            label="Contract Address"
            name="contract_address"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.contract_address}
            variant="outlined"
            margin="normal"
          />
          <Box sx={{ pt: 2 }}>
            <Button
              color="primary"
              disabled={
                isSubmitting ||
                !touched.contract_address ||
                (errors.contract_address && errors.contract_address.length > 0)
              }
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

const FillInContractAddress = ({ collection }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  return (
    <>
      <Button variant="contained" size="small" onClick={handleClick}>
        Got Address?
      </Button>
      <Modal
        open={showModal}
        onClose={handleModalClose}
        title="Add Contract Address"
        summary="Check your MetaMask logs to find if contract was successfully deployed. And copy the deployed contract address below."
        content={
          <FormContractAddress
            collection={collection}
            onSave={handleModalClose}
          />
        }
      />
    </>
  );
};

export default FillInContractAddress;
