import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@material-ui/core";
import { useSessionState } from "src/state";

const Favourites = () => {
  const navigate = useNavigate();
  const sessionState = useSessionState();

  const [errorMessage, setErrorMessage] = useState("");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{}}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              password: Yup.string().max(255).required("Password is required"),
            })}
            onSubmit={(values, { setSubmitting }) => {}}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography align="center" variant="h2">
                    My Favourites
                  </Typography>
                  <Typography
                    align="center"
                    gutterBottom
                    variant="body1"
                    sx={{ my: 3 }}
                  >
                    You haven't favorited any items yet
                  </Typography>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Favourites;
