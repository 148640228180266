import { useState } from "react";
import { Box, Button, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Delete";

const PropertyItem = ({ data, onDelete, onChange }) => {
	return (
		<Grid container sx={{ alignItems: "center" }}>
			<Grid item xs={2}>
				<IconButton onClick={onDelete}>
					<RemoveIcon />
				</IconButton>
			</Grid>
			<Grid item xs={5}>
				<TextField fullWidth margin="normal" name="title" value={data.title} onChange={onChange} />
			</Grid>
			<Grid item xs={5}>
				<TextField fullWidth margin="normal" name="value" value={data.value} onChange={onChange} />
			</Grid>
		</Grid>
	);
};

const PropertyEditor = ({ properties, onSave }) => {
	const defaultItem = { title: "", value: "" };

	const [listItems, setListItems] = useState(
		!properties || !properties.length ? [{ ...defaultItem }] : [...properties]
	);

	const handleDelete = index => {
		if (listItems.length <= 1) {
			setListItems([{ ...defaultItem }]);
			return;
		}

		const list = [...listItems];
		list.splice(index, 1);
		setListItems([...list]);
	};

	const handleAddMore = () => {
		setListItems([...listItems, { ...defaultItem }]);
	};

	const handleChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...listItems];
		list[index][name] = value;
		setListItems(list);
	};

	const handleSave = () => onSave(listItems);

	return (
		<Box>
			<Typography variant="body2">
				Properties show up underneath your item, are clickable, and can be filtered in your collection's
				sidebar.
			</Typography>
			<Grid container mt={3}>
				<Grid item xs={2}></Grid>
				<Grid item xs={5}>
					<Typography variant="h5">Type</Typography>
				</Grid>
				<Grid item xs={5}>
					<Typography variant="h5">Name</Typography>
				</Grid>
			</Grid>
			{listItems.map((property, index) => (
				<PropertyItem
					data={property}
					onChange={e => handleChange(e, index)}
					onDelete={() => handleDelete(index)}
					key={index}
				/>
			))}
			<Box mt={2} sx={{textAlign: "center"}}>
				<Button variant="outlined" onClick={handleAddMore}>
					Add More
				</Button>
			</Box>
			<Box mt={3}>
                <Button variant="contained" size="large" onClick={handleSave} fullWidth>
                    Save
                </Button>
            </Box>
		</Box>
	);
};

export default PropertyEditor;
