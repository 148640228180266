const metadata_object = {
  "title": "{{item_name}}",
  "type": "object",
  "description": "{{description}}",
  "external_url": "{{external_url}}",
  "name": "{{item_name}}",
  "properties": {
    "name": {
      "type": "string",
      "description": "{{item_name}}"
    },
    "description": {
      "type": "string",
      "description": "{{description}}"
    }
  },
  "attributes": [],
}

export default metadata_object;