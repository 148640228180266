import { Box, Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import ListingItem from "src/components/frontend/ListingItem";
import TokenItem from "./TokenItem";

const ListingContainer = styled("div")(({ theme }) => ({
	padding: "25px 0",
}));

const Listings = ({ listings, withScratch, canFlip, tokens, title, ...props }) => {
	return (
		<ListingContainer>
			<Box>
				{title && <h2 style={{ marginBottom: "15px" }}>{title}</h2>}
				<Grid container spacing={3}>
					{listings &&
						listings.map((listing, index) =>
							listing ? <ListingItem withScratch={withScratch} canFlip={canFlip} listing={listing} {...props} /> : null
						)}
					{tokens &&
						tokens.map((token, index) =>
							token ? <TokenItem withScratch={withScratch} canFlip={canFlip} token={token} {...props} key={index} /> : null
						)}
				</Grid>
			</Box>
		</ListingContainer>
	);
};

export default Listings;