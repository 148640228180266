import { createState, useState, none } from "@hookstate/core";
import { useEffect } from "react";
import {
  fetchCollectionTokens,
  addNewCollectionToken,
  updateCollectionToken,
  deleteCollectionToken,
  setupTokenData,
} from "src/db/firebase/collections";
import { useCollectionState } from "src/state";

// export interface Token {
//     token_id: string;
//     image_url: string;
//     metadata_url: string;
//     owner: string;
//     minter: string;
//     sku: string;
//     created_at: number;
// }

const tokensState = createState({});

export function useTokenState(collectionID) {
  const state = useState(tokensState);
  const loading = useState(false);

  const { incrementTokenCount, updateMeta } = useCollectionState();

  const loadTokens = async () => {
    try {
      loading.set(true);

      const loaded = state[collectionID]?.get();

      if (!loaded) {
        const tokens = await fetchCollectionTokens(collectionID);
        const obj = {};
        obj[collectionID] = tokens ?? [];
        state.merge(obj);
      }

      loading.set(false);
    } catch (e) {}
  };

  useEffect(() => {
    loadTokens();
  }, []);

  return {
    get promised() {
      return loading.value;
    },
    get tokens() {
      const t = state[collectionID]?.value;
      return t ? t : [];
    },
    getToken(collectionID, tokenID) {
      const t = state[collectionID]?.value;
      if (!t) return {};

      const token = t.find((item) => {
        return item.tokenID === parseInt(tokenID);
      });

      return token;
    },
    getTokenBySku(collectionID, sku) {
      const t = state[collectionID]?.value;
      if (!t) return null;

      const token = t.find((item) => item.sku === sku);

      return token;
    },
    async createToken(collectionID, data, metadata = false) {
      // send to db
      const dbID = await addNewCollectionToken(collectionID, data, metadata);

      const t = state[collectionID]?.value;
      const tokenData = setupTokenData(dbID, data);

      tokenData.id = dbID;

      if (!t) {
        const obj = {};
        obj[collectionID] = [tokenData];
        state.merge(obj);
      } else {
        state[collectionID].merge([tokenData]);
      }

      // increment collection token count within state
      incrementTokenCount(collectionID);
      if (metadata) updateMeta(collectionID, metadata);

      return dbID;
    },
    updateToken(collectionID, dbID, data) {
      const t = state[collectionID]?.value;
      if (!t) return {};

      const idx = t.findIndex((i) => i.id === dbID);
      if (idx >= 0) {
        state[collectionID][idx].merge({ tokenID: parseInt(data.tokenID) });
      }

      // send to db
      updateCollectionToken(collectionID, dbID, {
        token_id: data.tokenID,
      });
    },
    updateOwner(collectionID, dbID, data) {
      const t = state[collectionID]?.value;
      if (!t) return {};

      const idx = t.findIndex((i) => i.id === dbID);
      if (idx >= 0) {
        state[collectionID][idx].merge({ owner: data.owner });
      }

      // send to db
      updateCollectionToken(collectionID, dbID, {
        owner: data.owner,
      });
    },
    setAsBurnt(collectionID, dbID, data) {
      const t = state[collectionID]?.value;
      if (!t) return {};

      const idx = t.findIndex((i) => i.id === dbID);
      if (idx >= 0) {
        state[collectionID][idx].merge({ burnt: true });
      }

      // send to db
      updateCollectionToken(collectionID, dbID, {
        ...data,
      });
    },
    deleteFailedToken(collectionID, dbID) {
      const t = state[collectionID]?.value;
      if (!t) return {};

      const idx = t.findIndex((i) => i.id === dbID);
      if (idx >= 0) {
        state[collectionID][idx].set(none);
      }

      // decrement collection token count within state
      incrementTokenCount(collectionID, -1);

      // send to db
      deleteCollectionToken(collectionID, dbID);
    },
  };
}
