import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
	dialogContent: {
		width: 500,
		maxWidth: "100%",
	},
}));

const Modal = props => {
	const [_open, _setOpen] = React.useState(false);
	const classes = useStyles();

	const { open, title, summary, content, fullWidth, actionButtons, showOkButton, onClose } = props;

	useEffect(() => {
		if (open && !_open) _setOpen(true);
		else if (!open) _setOpen(false);
	}, [open]);

	const handleClose = () => {
		_setOpen(false);
		if (onClose) setTimeout(onClose, 100);
	};

	return (
		<Dialog
			open={_open}
			fullWidth={fullWidth ?? false}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
			PaperProps={{ style: { background: "#16182D" } }}
		>
			{title && (
				<DialogTitle>
					<Typography id="form-dialog-title">{title}</Typography>
				</DialogTitle>
			)}
			<DialogContent className={!fullWidth ? classes.dialogContent : null}>
				{summary && <DialogContentText sx={{ mb: 1 }}>{summary}</DialogContentText>}
				{_open && content}
			</DialogContent>
			{(actionButtons || showOkButton) && (
				<DialogActions>
					{actionButtons}
					{showOkButton && (
						<Button variant="contained" color="primary" onClick={handleClose}>
							OK
						</Button>
					)}
				</DialogActions>
			)}
		</Dialog>
	);
};

Modal.prototype = {
	title: PropTypes.string.isRequired,
	summary: PropTypes.string,
	content: PropTypes.element,
	actionButtons: PropTypes.element,
	onClose: PropTypes.func.isRequired,
};

export default Modal;
