import { useState, useRef } from "react";
import { Box, Button, CircularProgress, Grid } from "@material-ui/core";
import { readBlobToBuffer, uploadFileToIPFS } from "src/helpers/file.helper";
import { hash_to_ipfs_link } from "src/helpers/links.helper";
import { asyncForEach } from "src/helpers/array.helper";
import ImagePicker from "src/components/ImagePicker";

const GalleryEditor = ({ images, onSave }) => {
	const defaultItem = { imageURL: "" };

	const [galleryItems, setGalleryItems] = useState(!images || !images.length ? [{ ...defaultItem }] : [...images]);
	const [processing, setProcessing] = useState(false);
	const selectedImagesBuffer = useRef([]);

	const handleDelete = index => {
		selectedImagesBuffer.current.splice(index, 1);

		if (galleryItems.length <= 1) {
			setGalleryItems([{ ...defaultItem }]);
			return;
		}

		const list = [...galleryItems];
		list.splice(index, 1);
		setGalleryItems([...list]);
	};

	const handleAddMore = () => {
		setGalleryItems([...galleryItems, { ...defaultItem }]);
	};

	const handleFileSelect = (filename, file, index) => {
		if (file && file.type.match("^image/")) {
			readBlobToBuffer(file).then(imageBuffer => (selectedImagesBuffer.current[index] = imageBuffer));

			const list = [...galleryItems];
			list[index]["imageURL"] = URL.createObjectURL(file);
			setGalleryItems(list);
		}
	};

	const handleSave = async () => {
		if (processing) return;

		const uplaodedImages = [];
		const items = [...galleryItems];

		setProcessing(true);

		// upload the selected images and send back the URLs
		if (selectedImagesBuffer.current.length > 0) {
			await asyncForEach(selectedImagesBuffer.current, async (item, index) => {
				if (item) {
					const imageHash = await uploadFileToIPFS(item);
					if (imageHash) {
						const imageURL = hash_to_ipfs_link(imageHash);
						uplaodedImages.push({ imageURL });
						items[index]["imageURL"] = imageURL;
					}
				}
			});
		}

		const result = items.filter(item => item.imageURL !== "");

		if (uplaodedImages.length > 0) {
			setGalleryItems(result);
		}

		setProcessing(false);

		onSave(result);
	};

	return (
		<Box>
			<Grid container mt={3}>
				{galleryItems.map((item, index) => (
					<Grid item xs={6} key={index}>
						<ImagePicker
							withDragDrop
							singleColumn
							buttonProps={{ color: "primary", variant: "contained" }}
							onFileSelect={(filename, file) => handleFileSelect(filename, file, index)}
							onDelete={() => handleDelete(index)}
							value={item.imageURL}
						/>
					</Grid>
				))}
			</Grid>
			<Box mt={2} sx={{ textAlign: "center" }}>
				<Button variant="outlined" onClick={handleAddMore}>
					Add More
				</Button>
			</Box>
			<Box mt={3}>
				<Button variant="contained" disabled={processing} size="large" onClick={handleSave} fullWidth>
					{processing ? <CircularProgress size={20} /> : "Save"}
				</Button>
			</Box>
		</Box>
	);
};

export default GalleryEditor;
