import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Button,
  Box,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import Loading from "src/components/Loading";
import Tabs from "src/components/Tabs";
import {
  useCategoryState,
  useCollectionState,
  useProjectsState,
} from "src/state";
import Collections from "src/components/frontend/Collections";
import moment from "moment";
import ShareButton from "src/components/ShareButton";
import FileDownloadIcon from "@material-ui/icons/FileDownload";
import { cachedVersion } from "src/helpers/file.helper";

const ProjectContainer = styled("div")(({ theme }) => ({
  padding: "50px 0",
}));

const ProjectDetailsContainer = styled("div")(({ theme }) => ({
  padding: "50px",
  background: theme.palette.background.hero,
  color: theme.palette.primary.contrastText,
  marginLeft: -16,
  marginRight: -16,
}));

const useStyles = makeStyles((theme) => ({
  projectUsp: {
    minHeight: "85px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const Project = () => {
  const { pid: projectID } = useParams();
  const { promised: isCategoriesLoading, getCategory } = useCategoryState();
  const { promised: isCollectionsLoading, collections } = useCollectionState();
  const { promised: isProjectsLoading, getProject } = useProjectsState();

  const classes = useStyles();

  if (isCollectionsLoading || isProjectsLoading) return <Loading />;

  const project = getProject(projectID);

  const projectCategory =
    project.category && !isCategoriesLoading
      ? getCategory(project.category)
      : null;

  const open = (url) => {
    window.open(url, "_blank");
  };

  return (
    <ProjectContainer className="collection-contianer">
      <Box className="container" sx={{ maxWidth: 1100, mx: "auto" }}>
        <Grid container sx={{ py: 2, alignItems: "center" }}>
          <Grid item xs={6}>
            <Link component={RouterLink} to={"/"}>
              <ChevronLeftIcon sx={{ verticalAlign: "text-top" }} />
              Back to Projects
            </Link>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <ShareButton title="Share Project" />
          </Grid>
        </Grid>
        <Card>
          <CardMedia
            component="img"
            height="250"
            image={cachedVersion(project.project_image)}
            alt="green iguana"
          />
          <CardContent>
            <Box mb={4}>
              <Typography variant="h2">{project.project_name}</Typography>
              {/* <p
                      style={{
                        fontSize: 12,
                        marginTop: 5,
                        fontStyle: "italic",
                      }}
                    >
                      Project created in{" "}
                      {moment(project.created_at).format("MMM YYYY")}
                    </p> */}
              <Typography variant="bod2">
                {project.owner_name}, {project.project_country}
              </Typography>
              <Typography variant="h5" mt={2}>
                {project.capital_request} Capital Request
              </Typography>
            </Box>
            <Box>
              <Tabs tabPanelSx={{ p: 0 }}>
                <Box label="Collections">
                  <Box p={4}>
                    <Collections
                      collections={collections.filter(
                        (item) => item.projectID === projectID
                      )}
                    />
                  </Box>
                </Box>
                <Box label="Offer Details">
                  <Box p={{ xs: 2, md: 4 }}>
                    <Grid container>
                      <Grid item xs={6} sm={3} sx={{ textAlign: "center" }}>
                        <Typography variant="h3" mb={3}>
                          Offering Size
                        </Typography>
                        <Typography>{project.offering_size}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={3} sx={{ textAlign: "center" }}>
                        <Typography variant="h3" mb={3}>
                          Target IRR
                        </Typography>
                        <Typography>{project.target_irr}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={3} sx={{ textAlign: "center" }}>
                        <Typography variant="h3" mb={3}>
                          Sales Return
                        </Typography>
                        <Typography>
                          {project.sales_return_on_capital}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={3} sx={{ textAlign: "center" }}>
                        <Typography variant="h3" mb={3}>
                          Avg. Cash Yield
                        </Typography>
                        <Typography>{project.average_cash_yield}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  {project.project_details && (
                    <ProjectDetailsContainer>
                      <Typography variant="h3" mb={3}>
                        Project Summary
                      </Typography>
                      <Typography sx={{ whiteSpace: "pre-line" }}>
                        {project.project_details}
                      </Typography>
                      <Box>
                        <Grid container spacing={2} mt={2}>
                          <Grid item xs={6}>
                            <Box mb={1}>
                              <Typography variant="h6">Owner Name</Typography>
                              <Typography>{project.owner_name}</Typography>
                            </Box>
                            <Box mb={1}>
                              <Typography variant="h6">
                                Capital Request
                              </Typography>
                              <Typography>{project.capital_request}</Typography>
                            </Box>
                            <Box mb={1}>
                              <Typography variant="h6">Sales Return</Typography>
                              <Typography>{project.sales_return}</Typography>
                            </Box>
                            <Box mb={1}>
                              <Typography variant="h6">Reg. Cert.</Typography>
                              <Typography>
                                {project.registration_cert_updated}
                              </Typography>
                            </Box>
                            <Box mb={1}>
                              <Typography variant="h6">
                                Tax License No.
                              </Typography>
                              <Typography>{project.tax_license_no}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box mb={1}>
                              <Typography variant="h6">
                                Project Address
                              </Typography>
                              <Typography>{project.project_address}</Typography>
                            </Box>
                            <Box mb={1}>
                              <Typography variant="h6">Country</Typography>
                              <Typography>{project.project_country}</Typography>
                            </Box>
                            <Box mb={1}>
                              <Typography variant="h6">Licenses</Typography>
                              <Typography>{project.licenses}</Typography>
                            </Box>
                            <Box mb={1}>
                              <Typography variant="h6">
                                Distribution Partners
                              </Typography>
                              <Typography>
                                {project.distribution_partners}
                              </Typography>
                            </Box>
                            <Box mb={1}>
                              <Typography variant="h6">
                                Prove of Asset Registration
                              </Typography>
                              <Typography>
                                {project.prove_assets_registration}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </ProjectDetailsContainer>
                  )}
                  {(project.project_gallery_1 ||
                    project.project_gallery_2 ||
                    project.project_gallery_3) && (
                    <Box mx={-2} mb={5}>
                      <Grid container>
                        {project.project_gallery_1 && (
                          <Grid item xs={12} md={4}>
                            <img src={cachedVersion(project.project_gallery_1)} />
                          </Grid>
                        )}
                        {project.project_gallery_2 && (
                          <Grid item xs={12} md={4}>
                            <img src={cachedVersion(project.project_gallery_2)} />
                          </Grid>
                        )}
                        {project.project_gallery_3 && (
                          <Grid item xs={12} md={4}>
                            <img src={cachedVersion(project.project_gallery_3)} />
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  )}
                  {project.project_timeline && (
                    <Box sx={{ textAlign: "center" }} p={2} mb={5}>
                      <Typography variant="h1" mb={2}>
                        Project Timeline
                      </Typography>
                      <Typography variant="h3">
                        {project.project_timeline}
                      </Typography>
                      {project.timeline_image && (
                        <img src={cachedVersion(project.timeline_image)} />
                      )}
                    </Box>
                  )}
                  {project.use_funds && (
                    <Box sx={{ textAlign: "center" }} p={2} mb={5}>
                      <Typography variant="h1" mb={2}>
                        Use of Funds
                      </Typography>
                      <img src={cachedVersion(project.use_funds)} />
                    </Box>
                  )}
                  {project.project_map && (
                    <Box sx={{ textAlign: "center" }} p={2} mb={5}>
                      <Typography variant="h1" mb={2}>
                        Project Map
                      </Typography>
                      <img src={cachedVersion(project.project_map)} />
                      {project.project_map_desc && (
                        <Typography variant="h3">
                          {project.project_map_desc}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
                <Box label="Project USP">
                  <Box p={4}>
                    <Grid container spacing={2}>
                      {[1, 2, 3, 4, 5, 6].map((key) =>
                        project["usp_" + key] ? (
                          <Grid item xs={12} sm={6} md={3}>
                            <Card>
                              <CardContent className={classes.projectUsp} sx={{ textAlign: "center" }}>
                                <Typography variant="h6">{project["usp_" + key]}</Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ) : null
                      )}
                    </Grid>
                  </Box>
                </Box>
                <Box label="Video">
                  <Box p={4}>
                    <Typography variant="h3">Coming soon!</Typography>
                  </Box>
                </Box>
                <Box label="Documents">
                  <Box p={4}>
                    <Grid container spacing={2}>
                      {[1, 2, 3, 4, 5].map((key) =>
                        project["project_docs_" + key] ? (
                          <Grid item xs={12} sm={6} md={3}>
                            <Card>
                              <CardActionArea
                                href={project["project_docs_" + key]}
                                target="_blank"
                              >
                                <CardContent sx={{ textAlign: "center" }}>
                                  <FileDownloadIcon />
                                  <Typography variant="h6">
                                    View File
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          </Grid>
                        ) : null
                      )}
                    </Grid>
                  </Box>
                </Box>
              </Tabs>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </ProjectContainer>
  );
};

export default Project;
