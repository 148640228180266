import { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
// import { useTokenState, useCurrencyState } from "src/state";
// import { round } from "src/helpers/number.helper";
import { Link } from "react-router-dom";
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import SquareBlock from "src/components/frontend/SquareBlock";
import { cachedVersion } from "src/helpers/file.helper";
import { useSettingState } from "src/state";
import ScratchOff from "src/components/frontend/ScratchOff";
import { fetchFromLSArray, setInLSArray } from "src/helpers/localstorage.helper";

const useStyles = makeStyles(() => {
	return {
		listingImage: {
			width: "100%",
			height: "100%",
			objectFit: "cover",
			verticalAlign: "bottom",
			objectPosition: "top"
		},
		listingBlock: {
			border: "1px solid #23243e",
			borderRadius: "8px",
			background: "#16182d",
			overflow: "hidden",
			boxShadow: "0 0 1.25rem rgb(31 45 61 / 5%)",
		},
		listingItemContent: {
			padding: "15px",
		},
		listingItemDescription: {
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			overflow: "hidden",
		},
		listingItemPrice: {
			padding: "15px",
		},
	};
});

const TokenItem = ({ collectionID, token, name, description, linkTo, withScratch, canFlip, ...props }) => {
	const classes = useStyles();
	const [isScratched, setIsScratched] = useState(withScratch ? false : true)

	useEffect(() => {
		if (isScratched || !collectionID || !token) return;

		console.log(collectionID + token.tokenID);

		// check if with scratch and already scrached
		const checkIfAlreadyScratched = fetchFromLSArray("alreadyScratched", "id", collectionID + token.tokenID)
		if (checkIfAlreadyScratched) {
			setIsScratched(true)
		}
	}, [collectionID, token])

	const setScratched = () => {
		setIsScratched(true);
		setInLSArray("alreadyScratched", { "id": collectionID + token.tokenID })
	}

	if (!token || !token.imageUrl) return null;

	return (
		<Grid item xs={12} sm={6} lg={4}>
			<Box>
				<Box className={classes.listingBlock}>
					{isScratched ? <Link to={linkTo ? linkTo : `/listing/${collectionID}/${token.tokenID}`}>
						<SquareBlock>
							{canFlip ? <Flippy
								flipOnHover={true} // default false
								flipOnClick={false} // default false
								flipDirection="horizontal" // horizontal or vertical
							>
								<FrontSide>
									<img src={token.imageUrl} className={classes.listingImage} />
								</FrontSide>
								<BackSide>
									<img src={token.flippedImageUrl} className={classes.listingImage} />
								</BackSide>
							</Flippy> : <img src={token.imageUrl} className={classes.listingImage} />}
						</SquareBlock>
					</Link> : <SquareBlock>
						{
							<ScratchOff background={token.imageUrl
							} onfinished={setScratched} />}
					</SquareBlock>}
					<Box className={classes.listingItemContent}>
						<h2>{name ? name : token.itemName} ({token.sku})</h2>
						<p className={classes.listingItemDescription}>
							{description ? description : `@${process.env.REACT_APP_SITENAME}`}
						</p>
					</Box>
				</Box>
			</Box>
		</Grid >
	);
};

export default TokenItem;
