import { uploadVideo as uploadVideoToCache, checkVideoExists as checkVideoCached } from "src/plugins/Cloudinary";
import ipfs from "src/plugins/ipfs";

export const readBlobToBuffer = file =>
	new Promise((resolve, reject) => {
		if (file) {
			const reader = new window.FileReader();
			reader.readAsArrayBuffer(file);
			reader.onloadend = () => {
				resolve(Buffer(reader.result));
			};
		} else {
			reject("Must provide a file object");
		}
	});

export const uploadFileToIPFS = (fileBuffer, fileType = "image") =>
	new Promise((resolve, reject) => {
		ipfs.files.add(fileBuffer, (error, result) => {
			if (error) {
				reject(error);
			}

			const IPFSHash = result[0].hash;

			// for now adding in only for video as images able
			// to get directly from IPFS
			if (fileType === "video") {
				// check if not already cached
				checkVideoCached(IPFSHash).catch(error =>
					uploadVideoToCache(convertBufferToBlob(fileBuffer), IPFSHash)
				);
			}

			resolve(IPFSHash);
		});
	});

export const cachedVersion = (url, fileType = "image", width = null, height = null) => {
	// for now using cloudinary CDN for caching
	let cachedURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;
	let filename = url;

	if (fileType === "video") {
		const path = url.split("/");
		cachedURL += "video/upload/";
		filename = "videos/" + path[path.length - 1];
	} else {
		cachedURL += fileType + "/fetch/";
	}

	if (width || height) {
		cachedURL += "c_scale";
		if (fileType === "image") cachedURL += ",q_100";
		if (width) cachedURL += ",w_" + width;
		if (height) cachedURL += ",h_" + height;
		cachedURL += "/";
	}

	return cachedURL + filename;
};

export const convertBufferToBlob = fileBuffer => new Blob([fileBuffer]);
